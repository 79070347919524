<template>
    <v-tooltip top>
        <template v-slot:activator="{ on : tooltip }">
            <template v-if="!isPatientRecord">
                <v-btn v-on="{ ...tooltip }" elevation="2" tile icon aria-label="View Record" @click="view_record_app"><v-icon>fas fa-eye</v-icon></v-btn>
            </template>
            <template v-else-if="isPatientRecord">
                <template v-if="isPatientRecord === 0">
                    <v-btn v-on="{ ...tooltip }" elevation="2" tile icon aria-label="View Record" @click="view_record_app"><v-icon>fas fa-eye</v-icon></v-btn>
                </template>
            </template>
        </template>
        <span>View Record</span>
    </v-tooltip>
</template>

<script>
export default {
    props: ['isTargetBlank', 'list_type', 'patient_id', 'data', 'service_id', 'isPatientRecord'],
    methods: {
        view_record_app() {
            if(this.$props.isTargetBlank === true) {
                if(this.$props.data) {
                    if(this.$props.data.status_icon === null) {
                        this.$props.data.status_icon = 'Viewed';
                    }
                }
                let routeData;
                if(this.$props.service_id) {
                    routeData = this.$router.resolve({name: 'records_patient_record_service_information', params: {'service_id': this.$props.service_id, 'client_id': this.$props.patient_id}});
                } else {
                    routeData = this.$router.resolve({name: 'records_patient_record_service_information', params: {'service_id': this.$route.params.service_id, 'client_id': this.$props.patient_id}});
                }
                if(this.$common.test_is_app() === false) {
                    window.open(routeData.href, '_blank');
                } else {
                    window.open('index.html/' + routeData.href.replace(/\/app:\/.\//g, ''), '_blank', 'nodeIntegration=no');
                }
            } else {
                if(this.$props.service_id) {
                    this.$router.push({name: 'records_patient_record_service_information', params: {'service_id': this.$props.service_id, 'client_id': this.$props.patient_id}});
                } else {
                    this.$router.push({name: 'records_patient_record_service_information', params: {'service_id': this.$route.params.service_id, 'client_id': this.$props.patient_id}});
                }
            }
        }
    }
}
</script>
