<template>
    <div key="top_layer" class="loading_screen_overlay_main_loader" :style="isFirst === true ? 'background: #262626 !important;' : ''">
        <div class="loading_screen_branding_container">
            <img class="loading_screen_branding" alt="loading brand image" src="/images/branding/cdt_green.png"/>
            <div class="circle_container">
                <div class="circle_loader">
                    <div class="circle_dot_loader"></div>
                    <div class="circle_dot_loader"></div>
                    <div class="circle_dot_loader"></div>
                    <div class="circle_dot_loader"></div>
                    <div class="circle_dot_loader"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['isFirst']
    }
</script>