
export default {
    1: {
        "name": "Default",
        "preview": "/images/themes/Default.jpg",
        "primary": '#73a56e',
        "primary-rgb": '#73a56e2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#737373',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#57B982',
        "filter-gradient-two": '#19551E',
        "filter-gradient-three": '#57B982',
        "filter-gradient-four": '#19551E',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#73a56e',
        "shimmerfourtyfive": '#73A56E',
        "shimmerfifty": '#73A56E',
        "shimmerfiftyfive": '#73A56E',
        "tablerowhighlightfirst": '#73a56e70',
        "tablerowhighlightsecond": '#73a56e70',
        "tablerowhighlightthird": '#73a56e70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "exp-animation-hue": 85,
        "video": null,
        "playback_rate": "1",
        "background-image": 'Default.jpg',
        "background-gradient-one": '#00000000',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    2: {
        "name": "Halloween",
        "preview": "/images/themes/Halloween.jpg",
        "primary": '#9c5816',
        "primary-rgb": '#9c58162b',
        "primary-text": '#d2d2d2',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#b9610d',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#363636',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#9c5816',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b98857',
        "filter-gradient-two": '#553519',
        "filter-gradient-three": '#b98857',
        "filter-gradient-four": '#553519',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#9c5816',
        "shimmerfourtyfive": '#c3c3c3',
        "shimmerfifty": '#fff',
        "shimmerfiftyfive": '#c3c3c3',
        "tablerowhighlightfirst": '#9c581670',
        "tablerowhighlightsecond": '#9c581670',
        "tablerowhighlightthird": '#9c581670',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": 'Halloween',
        "exp-animation-hue": 30,
        "background-image": 'Halloween.jpg',
        "background-gradient-one": '#ffae6f87',
        "background-gradient-two": '#1f0e0061',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#7d6f16',
        "datepicker-shadow-deuteranopia": '#7d6f16',
        "shimmerfourtyfive-deuteranopia": '#7d6f16',
        "shimmerfifty-deuteranopia": '#7d6f16',
        "shimmerfiftyfive-deuteranopia": '#7d6f16',
        "tablerowhighlightfirst-deuteranopia": '#7d6f1670',
        "tablerowhighlightsecond-deuteranopia": '#7d6f1670',
        "tablerowhighlightthird-deuteranopia": '#7d6f1670',

        "primary-protanopia": '#6d5f08',
        "datepicker-shadow-protanopia": '#6d5f08',
        "shimmerfourtyfive-protanopia": '#6d5f08',
        "shimmerfifty-protanopia": '#6d5f08',
        "shimmerfiftyfive-protanopia": '#6d5f08',
        "tablerowhighlightfirst-protanopia": '#6d5f0870',
        "tablerowhighlightsecond-protanopia": '#6d5f0870',
        "tablerowhighlightthird-protanopia": '#6d5f0870',

        "primary-tritanopia": '#ab494c',
        "datepicker-shadow-tritanopia": '#ab494c',
        "shimmerfourtyfive-tritanopia": '#ab494c',
        "shimmerfifty-tritanopia": '#ab494c',
        "shimmerfiftyfive-tritanopia": '#ab494c',
        "tablerowhighlightfirst-tritanopia": '#ab494c70',
        "tablerowhighlightsecond-tritanopia": '#ab494c70',
        "tablerowhighlightthird-tritanopia": '#ab494c70'
    },
    3: {
        "name": "Christmas",
        "preview": "/images/themes/Christmas.jpg",
        "primary": '#7bb6ff',
        "primary-rgb": '#7bb6ff2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#7bb6ff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#7bb6ff',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#5785B9',
        "filter-gradient-two": '#193855',
        "filter-gradient-three": '#5785B9',
        "filter-gradient-four": '#193855',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#7bb6ff',
        "shimmerfourtyfive": '#c3c3c3',
        "shimmerfifty": '#fff',
        "shimmerfiftyfive": '#c3c3c3',
        "tablerowhighlightfirst": '#7bb6ff70',
        "tablerowhighlightsecond": '#7bb6ff70',
        "tablerowhighlightthird": '#7bb6ff70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#7bb6ff',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": 'Christmas',
        "exp-animation-hue": 180,
        "background-image": 'Christmas.jpg',
        "background-gradient-one": '#746fff87',
        "background-gradient-two": '#11141d61',

        "main-sidebar-image": 'none',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#88adfd',
        "datepicker-shadow-deuteranopia": '#88adfd',
        "shimmerfourtyfive-deuteranopia": '#88adfd',
        "shimmerfifty-deuteranopia": '#88adfd',
        "shimmerfiftyfive-deuteranopia": '#88adfd',
        "tablerowhighlightfirst-deuteranopia": '#88adfd70',
        "tablerowhighlightsecond-deuteranopia": '#88adfd70',
        "tablerowhighlightthird-deuteranopia": '#88adfd70',

        "primary-protanopia": '#99baff',
        "datepicker-shadow-protanopia": '#99baff',
        "shimmerfourtyfive-protanopia": '#99baff',
        "shimmerfifty-protanopia": '#99baff',
        "shimmerfiftyfive-protanopia": '#99baff',
        "tablerowhighlightfirst-protanopia": '#99baff70',
        "tablerowhighlightsecond-protanopia": '#99baff70',
        "tablerowhighlightthird-protanopia": '#99baff70',

        "primary-tritanopia": '#34c6d0',
        "datepicker-shadow-tritanopia": '#34c6d0',
        "shimmerfourtyfive-tritanopia": '#34c6d0',
        "shimmerfifty-tritanopia": '#34c6d0',
        "shimmerfiftyfive-tritanopia": '#34c6d0',
        "tablerowhighlightfirst-tritanopia": '#34c6d070',
        "tablerowhighlightsecond-tritanopia": '#34c6d070',
        "tablerowhighlightthird-tritanopia": '#34c6d070'
    },
    4: {
        "name": "Red",
        "preview": "/images/themes/Red.jpg",
        "primary": '#d66363',
        "primary-rgb": '#8421212b',
        "primary-text": '#bbb7b7',
        "primary-text-hover": '#ffffff',
        "link-hover": '#a5a5a5',
        "selected-text": '#ffffff',
        "primary-button": '#b33c3c',
        "primary-button-hover": '#d82727',
        "primary-button-background": '#2a2929',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bf0000',
        "background-color": '#2a2929',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#2a2929',
        "sidebar-color": '#2a2929',
        "sidebar-active-link-background-color": '#9c2222',
        "sidebar-active-link-color": '#2a2929',
        "sidebar-shadow-color" : '#d41a1a',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b95757',
        "filter-gradient-two": '#551919',
        "filter-gradient-three": '#b95757',
        "filter-gradient-four": '#551919',
        "datepicker-background-color": '#2a2929',
        "datepicker-shadow": '#b30a0a',
        "shimmerfourtyfive": '#a56e6e',
        "shimmerfifty": '#a56e6e',
        "shimmerfiftyfive": '#a56e6e',
        "tablerowhighlightfirst": '#84212170',
        "tablerowhighlightsecond": '#84212170',
        "tablerowhighlightthird": '#84212170',
        "background-color-form-head": '#2a2929',
        "pulse-color-green": '#b95757',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#000000',
        
        "particles": 'Circle',
        "exp-animation-hue": 0,
        "background-image": 'Default.jpg',
        "background-gradient-one": '#f3343494',
        "background-gradient-two": '#00000085',

        "main-sidebar-image": 'none',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#9b9061',
        "datepicker-shadow-deuteranopia": '#9b9061',
        "shimmerfourtyfive-deuteranopia": '#9b9061',
        "shimmerfifty-deuteranopia": '#9b9061',
        "shimmerfiftyfive-deuteranopia": '#9b9061',
        "tablerowhighlightfirst-deuteranopia": '#9b906170',
        "tablerowhighlightsecond-deuteranopia": '#9b906170',
        "tablerowhighlightthird-deuteranopia": '#9b906170',

        "primary-protanopia": '#7e7862',
        "datepicker-shadow-protanopia": '#7e7862',
        "shimmerfourtyfive-protanopia": '#7e7862',
        "shimmerfifty-protanopia": '#7e7862',
        "shimmerfiftyfive-protanopia": '#7e7862',
        "tablerowhighlightfirst-protanopia": '#7e786270',
        "tablerowhighlightsecond-protanopia": '#7e786270',
        "tablerowhighlightthird-protanopia": '#7e786270',

        "primary-tritanopia": '#e95164',
        "datepicker-shadow-tritanopia": '#e95164',
        "shimmerfourtyfive-tritanopia": '#e95164',
        "shimmerfifty-tritanopia": '#e95164',
        "shimmerfiftyfive-tritanopia": '#e95164',
        "tablerowhighlightfirst-tritanopia": '#e9516470',
        "tablerowhighlightsecond-tritanopia": '#e9516470',
        "tablerowhighlightthird-tritanopia": '#e9516470'
    },
    5: {
        "name": "Default (Red)",
        "preview": "/images/themes/Default (Red).jpg",
        "primary": '#a56e6e',
        "primary-rgb": '#a56e6e2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#ecbebe',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#a56e6e',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b95757',
        "filter-gradient-two": '#551919',
        "filter-gradient-three": '#b95757',
        "filter-gradient-four": '#551919',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#a56e6e',
        "shimmerfourtyfive": '#a56e6e',
        "shimmerfifty": '#a56e6e',
        "shimmerfiftyfive": '#a56e6e',
        "tablerowhighlightfirst": '#a56e6e70',
        "tablerowhighlightsecond": '#a56e6e70',
        "tablerowhighlightthird": '#a56e6e70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "exp-animation-hue": 0,
        "background-image": 'Default.jpg',
        "background-gradient-one": '#9e010157',
        "background-gradient-two": '#000000a3',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#86806d',
        "datepicker-shadow-deuteranopia": '#86806d',
        "shimmerfourtyfive-deuteranopia": '#86806d',
        "shimmerfifty-deuteranopia": '#86806d',
        "shimmerfiftyfive-deuteranopia": '#86806d',
        "tablerowhighlightfirst-deuteranopia": '#86806d70',
        "tablerowhighlightsecond-deuteranopia": '#86806d70',
        "tablerowhighlightthird-deuteranopia": '#86806d70',

        "primary-protanopia": '#78766e',
        "datepicker-shadow-protanopia": '#78766e',
        "shimmerfourtyfive-protanopia": '#78766e',
        "shimmerfifty-protanopia": '#78766e',
        "shimmerfiftyfive-protanopia": '#78766e',
        "tablerowhighlightfirst-protanopia": '#78766e70',
        "tablerowhighlightsecond-protanopia": '#778766e70',
        "tablerowhighlightthird-protanopia": '#78766e70',

        "primary-tritanopia": '#b0686e',
        "datepicker-shadow-tritanopia": '#b0686e',
        "shimmerfourtyfive-tritanopia": '#b0686e',
        "shimmerfifty-tritanopia": '#b0686e',
        "shimmerfiftyfive-tritanopia": '#b0686e',
        "tablerowhighlightfirst-tritanopia": '#b0686e70',
        "tablerowhighlightsecond-tritanopia": '#b0686e70',
        "tablerowhighlightthird-tritanopia": '#b0686e70'
    },
    6: {
        "name": "Default (Blue)",
        "preview": "/images/themes/Default (Blue).jpg",
        "primary": '#5078b9',
        "primary-rgb": '#5078b92b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#5078b9',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#5777b9',
        "filter-gradient-two": '#192755',
        "filter-gradient-three": '#577eb9',
        "filter-gradient-four": '#193155',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#596ba5',
        "shimmerfourtyfive": '#6e83a5',
        "shimmerfifty": '#6e83a5',
        "shimmerfiftyfive": '#6e85a5',
        "tablerowhighlightfirst": '#5078b970',
        "tablerowhighlightsecond": '#5078b970',
        "tablerowhighlightthird": '#5078b970',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "exp-animation-hue": 210,
        "background-image": 'Default.jpg',
        "background-gradient-one": '#010d9e57',
        "background-gradient-two": '#000000a3',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#5073b8',
        "datepicker-shadow-deuteranopia": '#5073b8',
        "shimmerfourtyfive-deuteranopia": '#5073b8',
        "shimmerfifty-deuteranopia": '#5073b8',
        "shimmerfiftyfive-deuteranopia": '#5073b8',
        "tablerowhighlightfirst-deuteranopia": '#5073b870',
        "tablerowhighlightsecond-deuteranopia": '#5073b870',
        "tablerowhighlightthird-deuteranopia": '#5073b870',

        "primary-protanopia": '#5d7dbc',
        "datepicker-shadow-protanopia": '#5d7dbc',
        "shimmerfourtyfive-protanopia": '#5d7dbc',
        "shimmerfifty-protanopia": '#5d7dbc',
        "shimmerfiftyfive-protanopia": '#5d7dbc',
        "tablerowhighlightfirst-protanopia": '#5d7dbc70',
        "tablerowhighlightsecond-protanopia": '#5d7dbc70',
        "tablerowhighlightthird-protanopia": '#5d7dbc70',

        "primary-tritanopia": '#008690',
        "datepicker-shadow-tritanopia": '#008690',
        "shimmerfourtyfive-tritanopia": '#008690',
        "shimmerfifty-tritanopia": '#008690',
        "shimmerfiftyfive-tritanopia": '#008690',
        "tablerowhighlightfirst-tritanopia": '#00869070',
        "tablerowhighlightsecond-tritanopia": '#00869070',
        "tablerowhighlightthird-tritanopia": '#00869070'
    },
    7: {
        "name": "Default (Yellow)",
        "preview": "/images/themes/Default (Yellow).jpg",
        "primary": '#a3a56e',
        "primary-rgb": '#a3a56e2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#a3a56e',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b5b957',
        "filter-gradient-two": '#535519',
        "filter-gradient-three": '#b3b957',
        "filter-gradient-four": '#545519',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#9ea56e',
        "shimmerfourtyfive": '#a1a56e',
        "shimmerfifty": '#a1a56e',
        "shimmerfiftyfive": '#a1a56e',
        "tablerowhighlightfirst": '#a3a56e70',
        "tablerowhighlightsecond": '#a3a56e70',
        "tablerowhighlightthird": '#a3a56e70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "exp-animation-hue": 60,
        "background-image": 'Default.jpg',
        "background-gradient-one": '#9c9e0157',
        "background-gradient-two": '#000000a3',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#aea270',
        "datepicker-shadow-deuteranopia": '#aea270',
        "shimmerfourtyfive-deuteranopia": '#aea270',
        "shimmerfifty-deuteranopia": '#aea270',
        "shimmerfiftyfive-deuteranopia": '#aea270',
        "tablerowhighlightfirst-deuteranopia": '#aea27070',
        "tablerowhighlightsecond-deuteranopia": '#aea27070',
        "tablerowhighlightthird-deuteranopia": '#aea27070',

        "primary-protanopia": '#ada06a',
        "datepicker-shadow-protanopia": '#ada06a',
        "shimmerfourtyfive-protanopia": '#ada06a',
        "shimmerfifty-protanopia": '#ada06a',
        "shimmerfiftyfive-protanopia": '#ada06a',
        "tablerowhighlightfirst-protanopia": '#ada06a70',
        "tablerowhighlightsecond-protanopia": '#ada06a70',
        "tablerowhighlightthird-protanopia": '#ada06a70',

        "primary-tritanopia": '#aa9f97',
        "datepicker-shadow-tritanopia": '#aa9f97',
        "shimmerfourtyfive-tritanopia": '#aa9f97',
        "shimmerfifty-tritanopia": '#aa9f97',
        "shimmerfiftyfive-tritanopia": '#aa9f97',
        "tablerowhighlightfirst-tritanopia": '#aa9f9770',
        "tablerowhighlightsecond-tritanopia": '#aa9f9770',
        "tablerowhighlightthird-tritanopia": '#aa9f9770'
    },
    8: {
        "name": "Autumn",
        "preview": "/images/themes/Autumn.jpg",
        "primary": '#e0ab28',
        "primary-rgb": '#e0ab282b',
        "primary-text": '#fdf1da',
        "primary-text-hover": '#fff',
        "link-hover": '#fff',
        "selected-text": '#fff',
        "primary-button": '#ffffff',
        "primary-button-hover": '#f59c41',
        "primary-button-background": '#232322',
        "primary-button-background-hover": '#000',
        "primary-menu-text": '#ffe2a9',
        "background-color": '#232322',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#232322',
        "sidebar-color": '#232322',
        "sidebar-active-link-background-color": '#e0ab28',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#e0ab28',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#ff8f1f',
        "filter-gradient-two": '#885529',
        "filter-gradient-three": '#b9b657',
        "filter-gradient-four": '#3b6310',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#e0ab28',
        "shimmerfourtyfive": '#e0ab28',
        "shimmerfifty": '#7ec360',
        "shimmerfiftyfive": '#885529',
        "tablerowhighlightfirst":'#e0ab2870',
        "tablerowhighlightsecond": '#e0ab2870',
        "tablerowhighlightthird": '#e0ab2870',
        "background-color-form-head": '#363636',
        "pulse-color-green": '#57b9a8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "exp-animation-hue": 60,
        "background-image": 'Autumn.jpg',
        "background-gradient-one": '#8a460087',
        "background-gradient-two": '#63844b61',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#ceb930',
        "datepicker-shadow-deuteranopia": '#ceb930',
        "shimmerfourtyfive-deuteranopia": '#ceb930',
        "shimmerfifty-deuteranopia": '#ceb930',
        "shimmerfiftyfive-deuteranopia": '#ceb930',
        "tablerowhighlightfirst-deuteranopia": '#ceb93070',
        "tablerowhighlightsecond-deuteranopia": '#ceb93070',
        "tablerowhighlightthird-deuteranopia": '#ceb93070',

        "primary-protanopia": '#c2ab00',
        "datepicker-shadow-protanopia": '#c2ab00',
        "shimmerfourtyfive-protanopia": '#c2ab00',
        "shimmerfifty-protanopia": '#c2ab00',
        "shimmerfiftyfive-protanopia": '#c2ab00',
        "tablerowhighlightfirst-protanopia": '#c2ab0070',
        "tablerowhighlightsecond-protanopia": '#c2ab0070',
        "tablerowhighlightthird-protanopia": '#c2ab0070',

        "primary-tritanopia": '#f49a93',
        "datepicker-shadow-tritanopia": '#f49a93',
        "shimmerfourtyfive-tritanopia": '#f49a93',
        "shimmerfifty-tritanopia": '#f49a93',
        "shimmerfiftyfive-tritanopia": '#f49a93',
        "tablerowhighlightfirst-tritanopia": '#f49a9370',
        "tablerowhighlightsecond-tritanopia": '#f49a9370',
        "tablerowhighlightthird-tritanopia": '#f49a9370'
    },
    9: {
        "name": "Winter",
        "preview": "/images/themes/Winter.jpg",
        "primary": '#5793d6',
        "primary-rgb": '#5793d62b',
        "primary-text": '#a8a8a8',
        "primary-text-hover": '#ffffff',
        "link-hover": '#ffffff',
        "selected-text": '#ffffff',
        "primary-button": '#ffffff',
        "primary-button-hover": '#65a7ea',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#91b7d0',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#65a7ea',
        "sidebar-active-link-color": '#000',
        "sidebar-shadow-color" : '#7e8db9',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#566396',
        "filter-gradient-two": '#254877',
        "filter-gradient-three": '#414f63',
        "filter-gradient-four": '#7792b7',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#566396',
        "shimmerfourtyfive": '#566396',
        "shimmerfifty": '#566396',
        "shimmerfiftyfive": '#96b6e2',
        "tablerowhighlightfirst": '#5793d670',
        "tablerowhighlightsecond": '#5793d670',
        "tablerowhighlightthird": '#5793d670',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57b9a8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "exp-animation-hue": 180,
        "background-image": 'Winter.jpg',
        "background-gradient-one": '#0000',
        "background-gradient-two": '#000f31',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#668ad5',
        "datepicker-shadow-deuteranopia": '#668ad5',
        "shimmerfourtyfive-deuteranopia": '#668ad5',
        "shimmerfifty-deuteranopia": '#668ad5',
        "shimmerfiftyfive-deuteranopia": '#668ad5',
        "tablerowhighlightfirst-deuteranopia": '#668ad570',
        "tablerowhighlightsecond-deuteranopia": '#668ad570',
        "tablerowhighlightthird-deuteranopia": '#668ad570',

        "primary-protanopia": '#7796d9',
        "datepicker-shadow-protanopia": '#7796d9',
        "shimmerfourtyfive-protanopia": '#7796d9',
        "shimmerfifty-protanopia": '#7796d9',
        "shimmerfiftyfive-protanopia": '#7796d9',
        "tablerowhighlightfirst-protanopia": '#7796d970',
        "tablerowhighlightsecond-protanopia": '#7796d970',
        "tablerowhighlightthird-protanopia": '#7796d970',

        "primary-tritanopia": '#00a2ab',
        "datepicker-shadow-tritanopia": '#00a2ab',
        "shimmerfourtyfive-tritanopia": '#00a2ab',
        "shimmerfifty-tritanopia": '#00a2ab',
        "shimmerfiftyfive-tritanopia": '#00a2ab',
        "tablerowhighlightfirst-tritanopia": '#00a2ab70',
        "tablerowhighlightsecond-tritanopia": '#00a2ab70',
        "tablerowhighlightthird-tritanopia": '#00a2ab70'
    },
    10: {
        "name": "Forest",
        "preview": "/images/themes/Forest.jpg",
        "primary": '#73a56e',
        "primary-rgb": '#73a56e2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#737373',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#57B982',
        "filter-gradient-two": '#19551E',
        "filter-gradient-three": '#57B982',
        "filter-gradient-four": '#19551E',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#73a56e',
        "shimmerfourtyfive": '#73A56E',
        "shimmerfifty": '#73A56E',
        "shimmerfiftyfive": '#73A56E',
        "tablerowhighlightfirst": '#73a56e70',
        "tablerowhighlightsecond": '#73a56e70',
        "tablerowhighlightthird": '#73a56e70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": 'Forest',
        "exp-animation-hue": 85,
        "background-image": 'Forest.jpg',
        "background-gradient-one": '#16420033',
        "background-gradient-two": '#2d110161',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    11: {
        "name": "Nebula",
        "preview": "/images/themes/Nebula.jpg",
        "primary": '#6e92a5',
        "primary-rgb": '#6e92a52b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#737373',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#57acb9',
        "filter-gradient-two": '#194855',
        "filter-gradient-three": '#57acb9',
        "filter-gradient-four": '#194855',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#6e92a5',
        "shimmerfourtyfive": '#57acb9',
        "shimmerfifty": '#57acb9',
        "shimmerfiftyfive": '#57acb9',
        "tablerowhighlightfirst": '#6e92a570',
        "tablerowhighlightsecond": '#6e92a570',
        "tablerowhighlightthird": '#6e92a570',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": 'Star',
        "exp-animation-hue": 185,
        "background-image": 'Nebula.jpg',
        "background-gradient-one": '#00000000',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#818aa5',
        "datepicker-shadow-deuteranopia": '#818aa5',
        "shimmerfourtyfive-deuteranopia": '#818aa5',
        "shimmerfifty-deuteranopia": '#818aa5',
        "shimmerfiftyfive-deuteranopia": '#818aa5',
        "tablerowhighlightfirst-deuteranopia": '#818aa5570',
        "tablerowhighlightsecond-deuteranopia": '#818aa5570',
        "tablerowhighlightthird-deuteranopia": '#818aa5570',

        "primary-protanopia": '#8991a6',
        "datepicker-shadow-protanopia": '#8991a6',
        "shimmerfourtyfive-protanopia": '#8991a6',
        "shimmerfifty-protanopia": '#8991a6',
        "shimmerfiftyfive-protanopia": '#8991a6',
        "tablerowhighlightfirst-protanopia": '#8991a670',
        "tablerowhighlightsecond-protanopia": '#8991a670',
        "tablerowhighlightthird-protanopia": '#8991a670',

        "primary-tritanopia": '#5c9798',
        "datepicker-shadow-tritanopia": '#5c9798',
        "shimmerfourtyfive-tritanopia": '#5c9798',
        "shimmerfifty-tritanopia": '#5c9798',
        "shimmerfiftyfive-tritanopia": '#5c9798',
        "tablerowhighlightfirst-tritanopia": '#5c979870',
        "tablerowhighlightsecond-tritanopia": '#5c979870',
        "tablerowhighlightthird-tritanopia": '#5c979870'
    },
    12: {
        "name": "Purple Geometry",
        "preview": "/images/themes/Purple Geometry.jpg",
        "primary": '#9b6ea5',
        "primary-rgb": '#9b6ea52b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#9b6ea5',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#ab57b9',
        "filter-gradient-two": '#4d1955',
        "filter-gradient-three": '#ab57b9',
        "filter-gradient-four": '#4d1955',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#9b6ea5',
        "shimmerfourtyfive": '#9b6ea5',
        "shimmerfifty": '#9b6ea5',
        "shimmerfiftyfive": '#9b6ea5',
        "tablerowhighlightfirst": '#9b6ea570',
        "tablerowhighlightsecond": '#9b6ea570',
        "tablerowhighlightthird": '#9b6ea570',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": 'Purple Geometry',
        "exp-animation-hue": 290,
        "background-image": 'Purple Geometry.jpg',
        "background-gradient-one": '#00000000',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#7280a3',
        "datepicker-shadow-deuteranopia": '#7280a3',
        "shimmerfourtyfive-deuteranopia": '#7280a3',
        "shimmerfifty-deuteranopia": '#7280a3',
        "shimmerfiftyfive-deuteranopia": '#7280a3',
        "tablerowhighlightfirst-deuteranopia": '#7280a370',
        "tablerowhighlightsecond-deuteranopia": '#7280a370',
        "tablerowhighlightthird-deuteranopia": '#7280a370',

        "primary-protanopia": '#677ba7',
        "datepicker-shadow-protanopia": '#677ba7',
        "shimmerfourtyfive-protanopia": '#677ba7',
        "shimmerfifty-protanopia": '#677ba7',
        "shimmerfiftyfive-protanopia": '#677ba7',
        "tablerowhighlightfirst-protanopia": '#677ba770',
        "tablerowhighlightsecond-protanopia": '#677ba770',
        "tablerowhighlightthird-protanopia": '#677ba770',

        "primary-tritanopia": '#9c7482',
        "datepicker-shadow-tritanopia": '#9c7482',
        "shimmerfourtyfive-tritanopia": '#9c7482',
        "shimmerfifty-tritanopia": '#9c7482',
        "shimmerfiftyfive-tritanopia": '#9c7482',
        "tablerowhighlightfirst-tritanopia": '#9c748270',
        "tablerowhighlightsecond-tritanopia": '#9c748270',
        "tablerowhighlightthird-tritanopia": '#9c748270'
    },
    13: {
        "name": "Default (Purple)",
        "preview": "/images/themes/Default (Purple).jpg",
        "primary": '#986ea5',
        "primary-rgb": '#986ea52b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#e7beec',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#986ea5',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b657b9',
        "filter-gradient-two": '#421955',
        "filter-gradient-three": '#b657b9',
        "filter-gradient-four": '#421955',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#986ea5',
        "shimmerfourtyfive": '#986ea5',
        "shimmerfifty": '#986ea5',
        "shimmerfiftyfive": '#986ea5',
        "tablerowhighlightfirst": '#986ea570',
        "tablerowhighlightsecond": '#986ea570',
        "tablerowhighlightthird": '#986ea570',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "exp-animation-hue": 85,
        "background-image": 'Default.jpg',
        "background-gradient-one": '#9c019e57',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    14: {
        "name": "Fog (Green)",
        "preview": "/images/themes/Fog (Green).jpg",
        "primary": '#73a56e',
        "primary-rgb": '#73a56e2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#737373',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#57B982',
        "filter-gradient-two": '#19551E',
        "filter-gradient-three": '#57B982',
        "filter-gradient-four": '#19551E',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#73a56e',
        "shimmerfourtyfive": '#73A56E',
        "shimmerfifty": '#73A56E',
        "shimmerfiftyfive": '#73A56E',
        "tablerowhighlightfirst": '#73a56e70',
        "tablerowhighlightsecond": '#73a56e70',
        "tablerowhighlightthird": '#73a56e70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'fog',
        "exp-animation-hue": 85,
        "background-image": 'none',
        "background-gradient-one": '#00000000',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    15: {
        "name": "Fog (Red)",
        "preview": "/images/themes/Fog (Red).jpg",
        "primary": '#a56e6e',
        "primary-rgb": '#a56e6e2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#ecbebe',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#a56e6e',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b95757',
        "filter-gradient-two": '#551919',
        "filter-gradient-three": '#b95757',
        "filter-gradient-four": '#551919',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#a56e6e',
        "shimmerfourtyfive": '#a56e6e',
        "shimmerfifty": '#a56e6e',
        "shimmerfiftyfive": '#a56e6e',
        "tablerowhighlightfirst": '#a56e6e70',
        "tablerowhighlightsecond": '#a56e6e70',
        "tablerowhighlightthird": '#a56e6e70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'fog',
        "exp-animation-hue": 0,
        "background-image": 'None',
        "background-gradient-one": '#9e010157',
        "background-gradient-two": '#000000a3',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#86806d',
        "datepicker-shadow-deuteranopia": '#86806d',
        "shimmerfourtyfive-deuteranopia": '#86806d',
        "shimmerfifty-deuteranopia": '#86806d',
        "shimmerfiftyfive-deuteranopia": '#86806d',
        "tablerowhighlightfirst-deuteranopia": '#86806d70',
        "tablerowhighlightsecond-deuteranopia": '#86806d70',
        "tablerowhighlightthird-deuteranopia": '#86806d70',

        "primary-protanopia": '#78766e',
        "datepicker-shadow-protanopia": '#78766e',
        "shimmerfourtyfive-protanopia": '#78766e',
        "shimmerfifty-protanopia": '#78766e',
        "shimmerfiftyfive-protanopia": '#78766e',
        "tablerowhighlightfirst-protanopia": '#78766e70',
        "tablerowhighlightsecond-protanopia": '#778766e70',
        "tablerowhighlightthird-protanopia": '#78766e70',

        "primary-tritanopia": '#b0686e',
        "datepicker-shadow-tritanopia": '#b0686e',
        "shimmerfourtyfive-tritanopia": '#b0686e',
        "shimmerfifty-tritanopia": '#b0686e',
        "shimmerfiftyfive-tritanopia": '#b0686e',
        "tablerowhighlightfirst-tritanopia": '#b0686e70',
        "tablerowhighlightsecond-tritanopia": '#b0686e70',
        "tablerowhighlightthird-tritanopia": '#b0686e70'
    },
    16: {
        "name": "Fog (Blue)",
        "preview": "/images/themes/Fog (Blue).jpg",
        "primary": '#5078b9',
        "primary-rgb": '#5078b92b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#5078b9',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#5777b9',
        "filter-gradient-two": '#192755',
        "filter-gradient-three": '#577eb9',
        "filter-gradient-four": '#193155',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#596ba5',
        "shimmerfourtyfive": '#6e83a5',
        "shimmerfifty": '#6e83a5',
        "shimmerfiftyfive": '#6e85a5',
        "tablerowhighlightfirst": '#5078b970',
        "tablerowhighlightsecond": '#5078b970',
        "tablerowhighlightthird": '#5078b970',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'fog',
        "exp-animation-hue": 210,
        "background-image": 'None',
        "background-gradient-one": '#010d9e57',
        "background-gradient-two": '#000000a3',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#5073b8',
        "datepicker-shadow-deuteranopia": '#5073b8',
        "shimmerfourtyfive-deuteranopia": '#5073b8',
        "shimmerfifty-deuteranopia": '#5073b8',
        "shimmerfiftyfive-deuteranopia": '#5073b8',
        "tablerowhighlightfirst-deuteranopia": '#5073b870',
        "tablerowhighlightsecond-deuteranopia": '#5073b870',
        "tablerowhighlightthird-deuteranopia": '#5073b870',

        "primary-protanopia": '#5d7dbc',
        "datepicker-shadow-protanopia": '#5d7dbc',
        "shimmerfourtyfive-protanopia": '#5d7dbc',
        "shimmerfifty-protanopia": '#5d7dbc',
        "shimmerfiftyfive-protanopia": '#5d7dbc',
        "tablerowhighlightfirst-protanopia": '#5d7dbc70',
        "tablerowhighlightsecond-protanopia": '#5d7dbc70',
        "tablerowhighlightthird-protanopia": '#5d7dbc70',

        "primary-tritanopia": '#008690',
        "datepicker-shadow-tritanopia": '#008690',
        "shimmerfourtyfive-tritanopia": '#008690',
        "shimmerfifty-tritanopia": '#008690',
        "shimmerfiftyfive-tritanopia": '#008690',
        "tablerowhighlightfirst-tritanopia": '#00869070',
        "tablerowhighlightsecond-tritanopia": '#00869070',
        "tablerowhighlightthird-tritanopia": '#00869070'
    },
    17: {
        "name": "Fog (Yellow)",
        "preview": "/images/themes/Fog (Yellow).jpg",
        "primary": '#a3a56e',
        "primary-rgb": '#a3a56e2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#a3a56e',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b5b957',
        "filter-gradient-two": '#535519',
        "filter-gradient-three": '#b3b957',
        "filter-gradient-four": '#545519',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#9ea56e',
        "shimmerfourtyfive": '#a1a56e',
        "shimmerfifty": '#a1a56e',
        "shimmerfiftyfive": '#a1a56e',
        "tablerowhighlightfirst": '#a3a56e70',
        "tablerowhighlightsecond": '#a3a56e70',
        "tablerowhighlightthird": '#a3a56e70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'fog',
        "exp-animation-hue": 60,
        "background-image": 'None',
        "background-gradient-one": '#9c9e0157',
        "background-gradient-two": '#000000a3',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#aea270',
        "datepicker-shadow-deuteranopia": '#aea270',
        "shimmerfourtyfive-deuteranopia": '#aea270',
        "shimmerfifty-deuteranopia": '#aea270',
        "shimmerfiftyfive-deuteranopia": '#aea270',
        "tablerowhighlightfirst-deuteranopia": '#aea27070',
        "tablerowhighlightsecond-deuteranopia": '#aea27070',
        "tablerowhighlightthird-deuteranopia": '#aea27070',

        "primary-protanopia": '#ada06a',
        "datepicker-shadow-protanopia": '#ada06a',
        "shimmerfourtyfive-protanopia": '#ada06a',
        "shimmerfifty-protanopia": '#ada06a',
        "shimmerfiftyfive-protanopia": '#ada06a',
        "tablerowhighlightfirst-protanopia": '#ada06a70',
        "tablerowhighlightsecond-protanopia": '#ada06a70',
        "tablerowhighlightthird-protanopia": '#ada06a70',

        "primary-tritanopia": '#aa9f97',
        "datepicker-shadow-tritanopia": '#aa9f97',
        "shimmerfourtyfive-tritanopia": '#aa9f97',
        "shimmerfifty-tritanopia": '#aa9f97',
        "shimmerfiftyfive-tritanopia": '#aa9f97',
        "tablerowhighlightfirst-tritanopia": '#aa9f9770',
        "tablerowhighlightsecond-tritanopia": '#aa9f9770',
        "tablerowhighlightthird-tritanopia": '#aa9f9770'
    },
    18: {
        "name": "Fog (Purple)",
        "preview": "/images/themes/Fog (Purple).jpg",
        "primary": '#986ea5',
        "primary-rgb": '#986ea52b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#e7beec',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#986ea5',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b657b9',
        "filter-gradient-two": '#421955',
        "filter-gradient-three": '#b657b9',
        "filter-gradient-four": '#421955',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#986ea5',
        "shimmerfourtyfive": '#986ea5',
        "shimmerfifty": '#986ea5',
        "shimmerfiftyfive": '#986ea5',
        "tablerowhighlightfirst": '#986ea570',
        "tablerowhighlightsecond": '#986ea570',
        "tablerowhighlightthird": '#986ea570',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'fog',
        "exp-animation-hue": 85,
        "background-image": 'None',
        "background-gradient-one": '#9c019e57',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'bg3.jpg',

        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    19: {
        "name": "Fog (Grey)",
        "preview": "/images/themes/Fog (Grey).jpg",
        "primary": '#636363',
        "primary-rgb": '#6363632b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#e5e5e5',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#636363',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b9b9b9',
        "filter-gradient-two": '#4b4b4b',
        "filter-gradient-three": '#b9b9b9',
        "filter-gradient-four": '#4b4b4b',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#636363',
        "shimmerfourtyfive": '#636363',
        "shimmerfifty": '#636363',
        "shimmerfiftyfive": '#636363',
        "tablerowhighlightfirst": '#ffffff70',
        "tablerowhighlightsecond": '#ffffff70',
        "tablerowhighlightthird": '#ffffff70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'fog',
        "exp-animation-hue": 85,
        "background-image": 'None',
        "background-gradient-one": '#00000000',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'bg3.jpg',
        
        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    20: {
        "name": "Clouds",
        "preview": "/images/themes/Clouds.jpg",
        "primary": '#aacce3',
        "primary-rgb": '#aacce32b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#e5e5e5',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#aacce3',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b9b9b9',
        "filter-gradient-two": '#4b4b4b',
        "filter-gradient-three": '#b9b9b9',
        "filter-gradient-four": '#4b4b4b',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#aacce3',
        "shimmerfourtyfive": '#aacce3',
        "shimmerfifty": '#aacce3',
        "shimmerfiftyfive": '#aacce3',
        "tablerowhighlightfirst": '#ffffff70',
        "tablerowhighlightsecond": '#ffffff70',
        "tablerowhighlightthird": '#ffffff70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'clouds',
        "exp-animation-hue": 180,
        "background-image": 'None',
        "background-gradient-one": '#00000000',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'none',

        "tab-group-active": '#3a3a3a29',
        "tab-group-not-active": '#262626a8',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    21: {
        "name": "Clouds 2",
        "preview": "/images/themes/Clouds 2.jpg",
        "primary": '#aacce3',
        "primary-rgb": '#aacce32b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#e5e5e5',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#aacce3',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b9b9b9',
        "filter-gradient-two": '#4b4b4b',
        "filter-gradient-three": '#b9b9b9',
        "filter-gradient-four": '#4b4b4b',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#aacce3',
        "shimmerfourtyfive": '#aacce3',
        "shimmerfifty": '#aacce3',
        "shimmerfiftyfive": '#aacce3',
        "tablerowhighlightfirst": '#ffffff70',
        "tablerowhighlightsecond": '#ffffff70',
        "tablerowhighlightthird": '#ffffff70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'clouds2',
        "exp-animation-hue": 180,
        "background-image": 'None',
        "background-gradient-one": '#00000000',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'none',

        "tab-group-active": '#3a3a3a29',
        "tab-group-not-active": '#262626a8',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    22: {
        "name": "Cells (Green)",
        "preview": "/images/themes/Cells (Green).jpg",
        "primary": '#73a56e',
        "primary-rgb": '#73a56e2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#737373',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#57B982',
        "filter-gradient-two": '#19551E',
        "filter-gradient-three": '#57B982',
        "filter-gradient-four": '#19551E',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#73a56e',
        "shimmerfourtyfive": '#73A56E',
        "shimmerfifty": '#73A56E',
        "shimmerfiftyfive": '#73A56E',
        "tablerowhighlightfirst": '#73a56e70',
        "tablerowhighlightsecond": '#73a56e70',
        "tablerowhighlightthird": '#73a56e70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'cells',
        "exp-animation-hue": 85,
        "background-image": 'none',
        "background-gradient-one": '#00000000',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'None',

        "tab-group-active": '#3a3a3a29',
        "tab-group-not-active": '#262626a8',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    23: {
        "name": "Cells (Red)",
        "preview": "/images/themes/Cells (Red).jpg",
        "primary": '#a56e6e',
        "primary-rgb": '#a56e6e2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#ecbebe',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#a56e6e',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b95757',
        "filter-gradient-two": '#551919',
        "filter-gradient-three": '#b95757',
        "filter-gradient-four": '#551919',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#a56e6e',
        "shimmerfourtyfive": '#a56e6e',
        "shimmerfifty": '#a56e6e',
        "shimmerfiftyfive": '#a56e6e',
        "tablerowhighlightfirst": '#a56e6e70',
        "tablerowhighlightsecond": '#a56e6e70',
        "tablerowhighlightthird": '#a56e6e70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'cells',
        "exp-animation-hue": 0,
        "background-image": 'None',
        "background-gradient-one": '#9e010157',
        "background-gradient-two": '#000000a3',

        "main-sidebar-image": 'None',

        "tab-group-active": '#3a3a3a29',
        "tab-group-not-active": '#262626a8',

        "primary-deuteranopia": '#86806d',
        "datepicker-shadow-deuteranopia": '#86806d',
        "shimmerfourtyfive-deuteranopia": '#86806d',
        "shimmerfifty-deuteranopia": '#86806d',
        "shimmerfiftyfive-deuteranopia": '#86806d',
        "tablerowhighlightfirst-deuteranopia": '#86806d70',
        "tablerowhighlightsecond-deuteranopia": '#86806d70',
        "tablerowhighlightthird-deuteranopia": '#86806d70',

        "primary-protanopia": '#78766e',
        "datepicker-shadow-protanopia": '#78766e',
        "shimmerfourtyfive-protanopia": '#78766e',
        "shimmerfifty-protanopia": '#78766e',
        "shimmerfiftyfive-protanopia": '#78766e',
        "tablerowhighlightfirst-protanopia": '#78766e70',
        "tablerowhighlightsecond-protanopia": '#778766e70',
        "tablerowhighlightthird-protanopia": '#78766e70',

        "primary-tritanopia": '#b0686e',
        "datepicker-shadow-tritanopia": '#b0686e',
        "shimmerfourtyfive-tritanopia": '#b0686e',
        "shimmerfifty-tritanopia": '#b0686e',
        "shimmerfiftyfive-tritanopia": '#b0686e',
        "tablerowhighlightfirst-tritanopia": '#b0686e70',
        "tablerowhighlightsecond-tritanopia": '#b0686e70',
        "tablerowhighlightthird-tritanopia": '#b0686e70'
    },
    24: {
        "name": "Cells (Blue)",
        "preview": "/images/themes/Cells (Blue).jpg",
        "primary": '#5078b9',
        "primary-rgb": '#5078b92b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#5078b9',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#5777b9',
        "filter-gradient-two": '#192755',
        "filter-gradient-three": '#577eb9',
        "filter-gradient-four": '#193155',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#596ba5',
        "shimmerfourtyfive": '#6e83a5',
        "shimmerfifty": '#6e83a5',
        "shimmerfiftyfive": '#6e85a5',
        "tablerowhighlightfirst": '#5078b970',
        "tablerowhighlightsecond": '#5078b970',
        "tablerowhighlightthird": '#5078b970',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'cells',
        "exp-animation-hue": 210,
        "background-image": 'None',
        "background-gradient-one": '#010d9e57',
        "background-gradient-two": '#000000a3',

        "main-sidebar-image": 'None',

        "tab-group-active": '#3a3a3a29',
        "tab-group-not-active": '#262626a8',

        "primary-deuteranopia": '#5073b8',
        "datepicker-shadow-deuteranopia": '#5073b8',
        "shimmerfourtyfive-deuteranopia": '#5073b8',
        "shimmerfifty-deuteranopia": '#5073b8',
        "shimmerfiftyfive-deuteranopia": '#5073b8',
        "tablerowhighlightfirst-deuteranopia": '#5073b870',
        "tablerowhighlightsecond-deuteranopia": '#5073b870',
        "tablerowhighlightthird-deuteranopia": '#5073b870',

        "primary-protanopia": '#5d7dbc',
        "datepicker-shadow-protanopia": '#5d7dbc',
        "shimmerfourtyfive-protanopia": '#5d7dbc',
        "shimmerfifty-protanopia": '#5d7dbc',
        "shimmerfiftyfive-protanopia": '#5d7dbc',
        "tablerowhighlightfirst-protanopia": '#5d7dbc70',
        "tablerowhighlightsecond-protanopia": '#5d7dbc70',
        "tablerowhighlightthird-protanopia": '#5d7dbc70',

        "primary-tritanopia": '#008690',
        "datepicker-shadow-tritanopia": '#008690',
        "shimmerfourtyfive-tritanopia": '#008690',
        "shimmerfifty-tritanopia": '#008690',
        "shimmerfiftyfive-tritanopia": '#008690',
        "tablerowhighlightfirst-tritanopia": '#00869070',
        "tablerowhighlightsecond-tritanopia": '#00869070',
        "tablerowhighlightthird-tritanopia": '#00869070'
    },
    25: {
        "name": "Cells (Yellow)",
        "preview": "/images/themes/Cells (Yellow).jpg",
        "primary": '#a3a56e',
        "primary-rgb": '#a3a56e2b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#a3a56e',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b5b957',
        "filter-gradient-two": '#535519',
        "filter-gradient-three": '#b3b957',
        "filter-gradient-four": '#545519',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#9ea56e',
        "shimmerfourtyfive": '#a1a56e',
        "shimmerfifty": '#a1a56e',
        "shimmerfiftyfive": '#a1a56e',
        "tablerowhighlightfirst": '#a3a56e70',
        "tablerowhighlightsecond": '#a3a56e70',
        "tablerowhighlightthird": '#a3a56e70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'cells',
        "exp-animation-hue": 60,
        "background-image": 'None',
        "background-gradient-one": '#9c9e0157',
        "background-gradient-two": '#000000a3',

        "main-sidebar-image": 'None',

        "tab-group-active": '#3a3a3a29',
        "tab-group-not-active": '#262626a8',

        "primary-deuteranopia": '#aea270',
        "datepicker-shadow-deuteranopia": '#aea270',
        "shimmerfourtyfive-deuteranopia": '#aea270',
        "shimmerfifty-deuteranopia": '#aea270',
        "shimmerfiftyfive-deuteranopia": '#aea270',
        "tablerowhighlightfirst-deuteranopia": '#aea27070',
        "tablerowhighlightsecond-deuteranopia": '#aea27070',
        "tablerowhighlightthird-deuteranopia": '#aea27070',

        "primary-protanopia": '#ada06a',
        "datepicker-shadow-protanopia": '#ada06a',
        "shimmerfourtyfive-protanopia": '#ada06a',
        "shimmerfifty-protanopia": '#ada06a',
        "shimmerfiftyfive-protanopia": '#ada06a',
        "tablerowhighlightfirst-protanopia": '#ada06a70',
        "tablerowhighlightsecond-protanopia": '#ada06a70',
        "tablerowhighlightthird-protanopia": '#ada06a70',

        "primary-tritanopia": '#aa9f97',
        "datepicker-shadow-tritanopia": '#aa9f97',
        "shimmerfourtyfive-tritanopia": '#aa9f97',
        "shimmerfifty-tritanopia": '#aa9f97',
        "shimmerfiftyfive-tritanopia": '#aa9f97',
        "tablerowhighlightfirst-tritanopia": '#aa9f9770',
        "tablerowhighlightsecond-tritanopia": '#aa9f9770',
        "tablerowhighlightthird-tritanopia": '#aa9f9770'
    },
    26: {
        "name": "Cells (Purple)",
        "preview": "/images/themes/Cells (Purple).jpg",
        "primary": '#986ea5',
        "primary-rgb": '#986ea52b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#e7beec',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#986ea5',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b657b9',
        "filter-gradient-two": '#421955',
        "filter-gradient-three": '#b657b9',
        "filter-gradient-four": '#421955',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#986ea5',
        "shimmerfourtyfive": '#986ea5',
        "shimmerfifty": '#986ea5',
        "shimmerfiftyfive": '#986ea5',
        "tablerowhighlightfirst": '#986ea570',
        "tablerowhighlightsecond": '#986ea570',
        "tablerowhighlightthird": '#986ea570',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'cells',
        "exp-animation-hue": 85,
        "background-image": 'None',
        "background-gradient-one": '#9c019e57',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'None',

        "tab-group-active": '#3a3a3a29',
        "tab-group-not-active": '#262626a8',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    27: {
        "name": "Cells (Grey)",
        "preview": "/images/themes/Cells (Grey).jpg",
        "primary": '#636363',
        "primary-rgb": '#6363632b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#e5e5e5',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#636363',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b9b9b9',
        "filter-gradient-two": '#4b4b4b',
        "filter-gradient-three": '#b9b9b9',
        "filter-gradient-four": '#4b4b4b',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#636363',
        "shimmerfourtyfive": '#636363',
        "shimmerfifty": '#636363',
        "shimmerfiftyfive": '#636363',
        "tablerowhighlightfirst": '#ffffff70',
        "tablerowhighlightsecond": '#ffffff70',
        "tablerowhighlightthird": '#ffffff70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": true,
        "vanta_animation": 'cells',
        "exp-animation-hue": 85,
        "background-image": 'None',
        "background-gradient-one": '#9c019e57',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'None',
        
        "tab-group-active": '#3a3a3a29',
        "tab-group-not-active": '#262626a8',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    28: {
        "name": "Waves",
        "preview": "/images/themes/Waves.jpg",
        "primary": '#5078b9',
        "primary-rgb": '#5078b92b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#5078b9',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#5777b9',
        "filter-gradient-two": '#192755',
        "filter-gradient-three": '#577eb9',
        "filter-gradient-four": '#193155',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#596ba5',
        "shimmerfourtyfive": '#6e83a5',
        "shimmerfifty": '#6e83a5',
        "shimmerfiftyfive": '#6e85a5',
        "tablerowhighlightfirst": '#5078b970',
        "tablerowhighlightsecond": '#5078b970',
        "tablerowhighlightthird": '#5078b970',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": false,
        "vanta_animation": 'none',
        "video": "/videos/video_backgrounds/2542703407.mp4",
        "playback_rate": "1",
        "exp-animation-hue": 85,
        "background-image": 'None',
        "background-gradient-one": '#01709e57',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'None',
        
        "tab-group-active": '#3a3a3a29',
        "tab-group-not-active": '#262626a8',

        "primary-deuteranopia": '#5073b8',
        "datepicker-shadow-deuteranopia": '#5073b8',
        "shimmerfourtyfive-deuteranopia": '#5073b8',
        "shimmerfifty-deuteranopia": '#5073b8',
        "shimmerfiftyfive-deuteranopia": '#5073b8',
        "tablerowhighlightfirst-deuteranopia": '#5073b870',
        "tablerowhighlightsecond-deuteranopia": '#5073b870',
        "tablerowhighlightthird-deuteranopia": '#5073b870',

        "primary-protanopia": '#5d7dbc',
        "datepicker-shadow-protanopia": '#5d7dbc',
        "shimmerfourtyfive-protanopia": '#5d7dbc',
        "shimmerfifty-protanopia": '#5d7dbc',
        "shimmerfiftyfive-protanopia": '#5d7dbc',
        "tablerowhighlightfirst-protanopia": '#5d7dbc70',
        "tablerowhighlightsecond-protanopia": '#5d7dbc70',
        "tablerowhighlightthird-protanopia": '#5d7dbc70',

        "primary-tritanopia": '#008690',
        "datepicker-shadow-tritanopia": '#008690',
        "shimmerfourtyfive-tritanopia": '#008690',
        "shimmerfifty-tritanopia": '#008690',
        "shimmerfiftyfive-tritanopia": '#008690',
        "tablerowhighlightfirst-tritanopia": '#00869070',
        "tablerowhighlightsecond-tritanopia": '#00869070',
        "tablerowhighlightthird-tritanopia": '#00869070'
    },
    29: {
        "name": "Animated Geometry",
        "preview": "/images/themes/Animated Geometry.jpg",
        "primary": '#636363',
        "primary-rgb": '#6363632b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#e5e5e5',
        "nav-color": '#36363682',
        "sidebar-color": '#36363682',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#636363',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#b9b9b9',
        "filter-gradient-two": '#4b4b4b',
        "filter-gradient-three": '#b9b9b9',
        "filter-gradient-four": '#4b4b4b',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#636363',
        "shimmerfourtyfive": '#636363',
        "shimmerfifty": '#636363',
        "shimmerfiftyfive": '#636363',
        "tablerowhighlightfirst": '#ffffff70',
        "tablerowhighlightsecond": '#ffffff70',
        "tablerowhighlightthird": '#ffffff70',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": false,
        "vanta_animation": 'none',
        "video": "/videos/video_backgrounds/867076032.mp4",
        "playback_rate": "1",
        "exp-animation-hue": 85,
        "background-image": 'None',
        "background-gradient-one": '#00000000',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'None',
        
        "tab-group-active": '#3a3a3adb',
        "tab-group-not-active": '#262626',

        "primary-deuteranopia": '#a09771',
        "datepicker-shadow-deuteranopia": '#a09771',
        "shimmerfourtyfive-deuteranopia": '#a09771',
        "shimmerfifty-deuteranopia": '#a09771',
        "shimmerfiftyfive-deuteranopia": '#a09771',
        "tablerowhighlightfirst-deuteranopia": '#a0977170',
        "tablerowhighlightsecond-deuteranopia": '#a0977170',
        "tablerowhighlightthird-deuteranopia": '#a0977170',

        "primary-protanopia": '#a89c6a',
        "datepicker-shadow-protanopia": '#a89c6a',
        "shimmerfourtyfive-protanopia": '#a89c6a',
        "shimmerfifty-protanopia": '#a89c6a',
        "shimmerfiftyfive-protanopia": '#a89c6a',
        "tablerowhighlightfirst-protanopia": '#a89c6a70',
        "tablerowhighlightsecond-protanopia": '#a89c6a70',
        "tablerowhighlightthird-protanopia": '#a89c6a70',

        "primary-tritanopia": '#6fa297',
        "datepicker-shadow-tritanopia": '#6fa297',
        "shimmerfourtyfive-tritanopia": '#6fa297',
        "shimmerfifty-tritanopia": '#6fa297',
        "shimmerfiftyfive-tritanopia": '#6fa297',
        "tablerowhighlightfirst-tritanopia": '#6fa29770',
        "tablerowhighlightsecond-tritanopia": '#6fa29770',
        "tablerowhighlightthird-tritanopia": '#6fa29770'
    },
    30: {
        "name": "Fish Tank",
        "preview": "/images/themes/Fish Tank.jpg",
        "primary": '#5078b9',
        "primary-rgb": '#5078b92b',
        "primary-text": '#fff',
        "primary-text-hover": '#fff',
        "link-hover": '#a5a5a5',
        "selected-text": '#fff',
        "primary-button": '#fff',
        "primary-button-hover": '#fff',
        "primary-button-background": '#363636',
        "primary-button-background-hover": '#2f2f2f',
        "primary-menu-text": '#bdbdbd',
        "background-color": '#363636',
        "control-border-colour" : '#dbdbdb',
        "nav-color": '#363636',
        "sidebar-color": '#363636',
        "sidebar-active-link-background-color": '#b3b3b3',
        "sidebar-active-link-color": '#000000',
        "sidebar-shadow-color" : '#5078b9',
        "popup-background" : '#363636',
        "dialog-background" : '#363636',
        "filter-gradient-one": '#5777b9',
        "filter-gradient-two": '#192755',
        "filter-gradient-three": '#577eb9',
        "filter-gradient-four": '#193155',
        "datepicker-background-color": '#363636',
        "datepicker-shadow": '#596ba5',
        "shimmerfourtyfive": '#6e83a5',
        "shimmerfifty": '#6e83a5',
        "shimmerfiftyfive": '#6e85a5',
        "tablerowhighlightfirst": '#5078b970',
        "tablerowhighlightsecond": '#5078b970',
        "tablerowhighlightthird": '#5078b970',
        "background-color-form-head": '#2b2b2b',
        "pulse-color-green": '#57B9A8',
        "pulse-color-amber": '#987715',
        "pulse-color-red": '#981515',

        "particles": null,
        "show_vanta": false,
        "vanta_animation": 'none',
        "video": "/videos/video_backgrounds/1351636943.mp4",
        "playback_rate": "0.5",
        "exp-animation-hue": 85,
        "background-image": 'None',
        "background-gradient-one": '#01709e57',
        "background-gradient-two": '#00000000',

        "main-sidebar-image": 'bg3.jpg',
        
        "tab-group-active": '#3a3a3a29',
        "tab-group-not-active": '#262626a8',

        "primary-deuteranopia": '#5073b8',
        "datepicker-shadow-deuteranopia": '#5073b8',
        "shimmerfourtyfive-deuteranopia": '#5073b8',
        "shimmerfifty-deuteranopia": '#5073b8',
        "shimmerfiftyfive-deuteranopia": '#5073b8',
        "tablerowhighlightfirst-deuteranopia": '#5073b870',
        "tablerowhighlightsecond-deuteranopia": '#5073b870',
        "tablerowhighlightthird-deuteranopia": '#5073b870',

        "primary-protanopia": '#5d7dbc',
        "datepicker-shadow-protanopia": '#5d7dbc',
        "shimmerfourtyfive-protanopia": '#5d7dbc',
        "shimmerfifty-protanopia": '#5d7dbc',
        "shimmerfiftyfive-protanopia": '#5d7dbc',
        "tablerowhighlightfirst-protanopia": '#5d7dbc70',
        "tablerowhighlightsecond-protanopia": '#5d7dbc70',
        "tablerowhighlightthird-protanopia": '#5d7dbc70',

        "primary-tritanopia": '#008690',
        "datepicker-shadow-tritanopia": '#008690',
        "shimmerfourtyfive-tritanopia": '#008690',
        "shimmerfifty-tritanopia": '#008690',
        "shimmerfiftyfive-tritanopia": '#008690',
        "tablerowhighlightfirst-tritanopia": '#00869070',
        "tablerowhighlightsecond-tritanopia": '#00869070',
        "tablerowhighlightthird-tritanopia": '#00869070'
    }
}