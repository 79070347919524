import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import VueSimpleSVG from 'vue-simple-svg'
import common from './scripts/common'
import export_data from './scripts/export'
import theme_functions from './scripts/theme_functions'
import data_render from './scripts/data_render'
import moment from 'moment'
// import IdleVue from 'idle-vue'
import { Icon, Loading, Modal, Slider, Dialog, Notification, Field, Datepicker, Datetimepicker, Upload, Timepicker, Radio } from 'buefy'
import browserDetect from "vue-browser-detect-plugin"
import VueMobileDetection from "vue-mobile-detection"
import VueRouterBackButton from 'vue-router-back-button'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import recordLink from "./components/buttons/recordLink.vue"
import passwordStrength from "./components/password/password_score.vue"
import VueTilt from 'vue-tilt.js'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import ToggleButton from 'vue-js-toggle-button'
import VueObserveVisibility from 'vue-observe-visibility'
import VueLazyload from 'vue-lazyload'
import Treeselect from '@riophae/vue-treeselect'
import { Icon as LIcon} from "leaflet"
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

import error_reporting from './api/errorHandling'
import routingAPI from './api/routingAPI'

Vue.config.productionTip = false
Vue.config.performance = true

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
Vue.component('recordLink', recordLink)
Vue.component('passwordStrength', passwordStrength)
Vue.component('vue-phone-number-input', VuePhoneNumberInput)

Vue.use(browserDetect)
Vue.use(VueMobileDetection)
Vue.use(VueSimpleSVG)
Vue.use(Icon)
Vue.use(Loading)
Vue.use(Modal)
Vue.use(VueTilt)
Vue.use(ToggleButton)
Vue.use(Slider)
Vue.use(Dialog)
Vue.use(Notification)
Vue.use(Field)
Vue.use(Datepicker)
Vue.use(Datetimepicker)
Vue.use(Upload)
Vue.use(Timepicker)
Vue.use(Radio)

Vue.prototype.moment = moment
Vue.prototype.$s3_bucket = process.env.VUE_APP_S3_BUCKET
Vue.prototype.$cdt_api_address = process.env.VUE_APP_cdt_api_address

/*const eventsHub = new Vue()
 
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 600000
})*/

const common_plugin = {
    install () {
        Vue.common = common
        Vue.prototype.$common = common
    }
}
Vue.use(common_plugin)

const export_data_plugin = {
    install () {
        Vue.export_data = export_data
        Vue.prototype.$export_data = export_data
    }
}
Vue.use(export_data_plugin)

const theme_plugin = {
    install () {
        Vue.theme_functions = theme_functions
        Vue.prototype.$theme_functions = theme_functions
    }
}
Vue.use(theme_plugin)

const data_render_plugin = {
    install () {
        Vue.data_render = data_render
        Vue.prototype.$data_render = data_render
    }
}
Vue.use(data_render_plugin)

const router_api_plugin = {
    install () {
        Vue.routingAPI = routingAPI
        Vue.prototype.$routingAPI = routingAPI
    }
}
Vue.use(router_api_plugin)

Vue.use(VueRouterBackButton, { router })

const socket_options = io(process.env.VUE_APP_cdt_socket, {transports: ["websocket"], withCredentials: true, autoConnect: false, timeout: 1e6, upgrade: false})

Vue.use(VueSocketIOExt, socket_options, { store })

Vue.use(VueObserveVisibility)

Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: require('./assets/images/other/error.png'),
    loading: require('./assets/images/other/loading-buffering.gif'),
    attempt: 1
})

Vue.component('treeselect', Treeselect)

delete LIcon.Default.prototype._getIconUrl;
LIcon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.use(VuePlyr, {
    plyr: {}
})

export const eventBus = new Vue()

Vue.config.errorHandler = (err, vm, info) => {
    console.log(err.stack);
    //error_reporting.report_error({err: err.stack, info: info});
};

new Vue({
    vuetify,
    router,
    store,
    render: function (h) { return h(App) }
}).$mount('#app')

