<template>
    <vtoast ref="vtoast_error"/>
</template>
<script>
import { eventBus } from "../../main";
import vtoast from './vtoast.vue';
export default {
    components: {
        vtoast
    },
    mounted() {
        var this_ = this;
        this_.$root.vtoast_error = this_.$refs.vtoast_error;
        eventBus.$on("error_display", function(message) {
            this_.$root.vtoast_error.show({timer: 8000, message: message, color: 'red', icon: 'mdi-alert-circle'});
            eventBus.$emit("play_error");
            eventBus.$emit("release_loading_button");
        });
    }
}
</script>
