import Vue from 'vue';
import VueRouter, { RawLocation, Route } from 'vue-router';
import store from '../store/index.js';
import userAPI from "../api/userAPI.js";
import { eventBus } from "../main";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err);
}

function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `../views/${view}.vue`)
}

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/account-activation',
        name: 'AccountActivation',
        component: () => import(/* webpackChunkName: "account-activation" */ '../views/AccountActivation.vue')
    },
    {
        path: '/first-login',
        name: 'FirstLogin',
        component: () => import(/* webpackChunkName: "password-first-login" */ '../views/FirstLogin.vue')
    },
    {
        path: '/multiple-device',
        name: 'Multiple',
        component: () => import(/* webpackChunkName: "multiple-device" */ '../views/MultipleDevice.vue')
    },
    {
        path: '/password-reset',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue')
    },
    {
        path: '/download-app',
        name: 'DownloadApp',
        component: () => import(/* webpackChunkName: "download-app" */ '../views/DownloadApp.vue')
    },
    {
        path: '/',
        alias: ['/home'],
        name: 'Main',
        component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
        children: [
            {
                path: '/service-management',
                name: 'ServiceManagement',
                component: () => import(/* webpackChunkName: "service-management" */ '../views/ServiceManagement.vue')
            },
            {
                path: '/how-to',
                name: 'HowTo',
                component: () => import(/* webpackChunkName: "about" */ '../views/HowTos.vue')
            },
            {
                path: '/services/acute/:service_id',
                name: 'ServicesAcuteRouter',
                component: () => import(/* webpackChunkName: "services-acute-router" */ '../views/services/routers/ServicesAcuteRouter.vue'),
                children: [
                    {
                        path: '/services/acute/selection',
                        name: 'ServicesAcuteSelection',
                        component: () => import(/* webpackChunkName: "services-acute-selection" */ '../views/services/service_loader/ServiceLoaderAcute.vue'),
                    },
                    {
                        path: '/services/acute/:service_id',
                        name: 'ServicesAcute',
                        component: () => import(/* webpackChunkName: "services-acute" */ '../views/services/ServicesAcute.vue'),
                        children: [
                            {
                                path: '/services/acute/:service_id/overview',
                                name: 'acute_services_overview',
                                redirect: '/services/acute/:service_id/patient-list',
                                //component: () => import(/* webpackChunkName: "services-acute-overview" */ '../views/services/acute/Overview.vue'),
                            },
                            {
                                path: '/services/acute/:service_id/patient-list',
                                name: 'acute_services_patient_list',
                                component: () => import(/* webpackChunkName: "services-acute-patient-list" */ '../views/services/acute/PatientList.vue'),
                            },
                            {
                                path: '/services/acute/:service_id/referrals-list',
                                name: 'acute_services_referrals',
                                component: () => import(/* webpackChunkName: "services-acute-referrals" */ '../views/services/acute/Referrals.vue'),
                            },
                            {
                                path: '/services/acute/:service_id/movers',
                                name: 'acute_services_movers',
                                component: () => import(/* webpackChunkName: "services-acute-movers" */ '../views/services/acute/Movers.vue'),
                            },
                            {
                                path: '/services/acute/:service_id/dtoc',
                                name: 'acute_services_dtoc',
                                component: () => import(/* webpackChunkName: "services-acute-dtoc" */ '../views/services/acute/DTOC.vue'),
                            },
                            {
                                path: '/services/acute/:service_id/staff-management',
                                name: 'acute_services_staff_management',
                                component: () => import(/* webpackChunkName: "services-acute-staff-management" */ '../views/services/acute/StaffManagement.vue'),
                            },
                            {
                                path: '/services/acute/:service_id/ward-management',
                                name: 'acute_services_ward_management',
                                component: () => import(/* webpackChunkName: "services-acute-ward-management" */ '../views/services/acute/WardManagement.vue'),
                            }
                        ]
                    }
                ]
            },
            {
                path: '/services/community-hospital/:service_id',
                name: 'ServicesCommunityHospitalRouter',
                component: () => import(/* webpackChunkName: "services-community-hospital-router" */ '../views/services/routers/ServicesCommunityHospitalRouter.vue'),
                children: [
                    {
                        path: '/services/community-hospital/selection',
                        name: 'ServicesCommunityHospitalSelection',
                        component: () => import(/* webpackChunkName: "services-community-hospital-selection" */ '../views/services/service_loader/ServiceLoaderCommunityHospital.vue'),
                    },
                    {
                        path: '/services/community-hospital/:service_id',
                        name: 'ServicesCommunityHospital',
                        component: () => import(/* webpackChunkName: "services-community-hospital" */ '../views/services/ServicesCommunityHospital.vue'),
                        children: [
                            {
                                path: '/services/community-hospital/:service_id/overview',
                                name: 'community_hospital_services_overview',
                                redirect: '/services/community-hospital/:service_id/patient-list',
                                //component: () => import(/* webpackChunkName: "services-community-hospital-overview" */ '../views/services/community_hospital/Overview.vue'),
                            },
                            {
                                path: '/services/community-hospital/:service_id/patient-list',
                                name: 'community_hospital_services_patient_list',
                                component: () => import(/* webpackChunkName: "services-community-hospital-patient-list" */ '../views/services/community_hospital/PatientList.vue'),
                            },
                            {
                                path: '/services/community-hospital/:service_id/waiting-list',
                                name: 'community_hospital_services_waiting_list',
                                component: () => import(/* webpackChunkName: "services-community-hospital-waiting-list" */ '../views/services/community_hospital/WaitingList.vue'),
                            },
                            {
                                path: '/services/community-hospital/:service_id/referrals-list',
                                name: 'community_hospital_services_referrals',
                                component: () => import(/* webpackChunkName: "services-community-hospital-referrals" */ '../views/services/community_hospital/Referrals.vue'),
                            },
                            {
                                path: '/services/community-hospital/:service_id/movers',
                                name: 'community_hospital_services_movers',
                                component: () => import(/* webpackChunkName: "services-community-hospital-movers" */ '../views/services/community_hospital/Movers.vue'),
                            },
                            {
                                path: '/services/community-hospital/:service_id/dtoc',
                                name: 'community_hospital_services_dtoc',
                                component: () => import(/* webpackChunkName: "services-community-hospital-dtoc" */ '../views/services/community_hospital/DTOC.vue'),
                            },
                            {
                                path: '/services/community-hospital/:service_id/staff-management',
                                name: 'community_hospital_services_staff_management',
                                component: () => import(/* webpackChunkName: "services-community-hospital-staff-management" */ '../views/services/community_hospital/StaffManagement.vue'),
                            },
                            {
                                path: '/services/community-hospital/:service_id/ward-management',
                                name: 'community_hospital_services_ward_management',
                                component: () => import(/* webpackChunkName: "services-community-hospital-ward-management" */ '../views/services/community_hospital/WardManagement.vue'),
                            }
                        ]
                    }
                ]
            },
            {
                path: '/services/home',
                name: 'ServicesHomeRouter',
                component: () => import(/* webpackChunkName: "services-home-router" */ '../views/services/routers/ServicesHomeRouter.vue'),
                children: [
                    {
                        path: '/services/home/selection',
                        name: 'ServicesHomeSelection',
                        component: () => import(/* webpackChunkName: "services-home-selection" */ '../views/services/service_loader/ServiceLoaderHome.vue'),
                    },
                    {
                        path: '/services/home/:service_id',
                        name: 'ServicesHome',
                        component: () => import(/* webpackChunkName: "services-home" */ '../views/services/ServicesHome.vue'),
                        children: [
                            {
                                path: '/services/home/:service_id/overview',
                                name: 'home_services_overview',
                                //redirect: '/services/home/:service_id/client-list',
                                component: () => import(/* webpackChunkName: "services-home-overview" */ '../views/services/home_services/Overview.vue'),
                            },
                            {
                                path: '/services/home/:service_id/client-list',
                                name: 'home_services_client_list',
                                // meta: {
                                //     keepAlive: true // Need to be cached
                                // },
                                component: () => import(/* webpackChunkName: "services-home-client-list" */ '../views/services/home_services/ClientList.vue'),
                            },
                            {
                                path: '/services/home/:service_id/waiting-list',
                                name: 'home_services_waiting_list',
                                component: () => import(/* webpackChunkName: "services-home-waiting-list" */ '../views/services/home_services/WaitingList.vue'),
                            },
                            {
                                path: '/services/home/:service_id/referrals',
                                name: 'home_services_referrals',
                                component: () => import(/* webpackChunkName: "services-home-referrals" */ '../views/services/home_services/Referrals.vue'),
                            },
                            {
                                path: '/services/home/:service_id/movers',
                                name: 'home_services_movers',
                                component: () => import(/* webpackChunkName: "services-home-movers" */ '../views/services/home_services/Movers.vue'),
                            },
                            {
                                path: '/services/home/:service_id/staff-management',
                                name: 'home_services_staff_management',
                                component: () => import(/* webpackChunkName: "services-home-staff-management" */ '../views/services/home_services/StaffManagement.vue'),
                            },
                            {
                                path: '/services/home/:service_id/shift-management',
                                name: 'home_services_shift_management',
                                component: () => import(/* webpackChunkName: "services-home-shift-management" */ '../views/services/home_services/ShiftManagement.vue'),
                            },
                            {
                                path: '/services/home/:service_id/route-planning',
                                name: 'home_services_route_planning',
                                component: () => import(/* webpackChunkName: "services-home-route-planning" */ '../views/services/home_services/RoutePlanning.vue'),
                                children: [
                                    {
                                        path: '/services/home/:service_id/route-planning/provider',
                                        name: 'home_services_route_planning_provider',
                                        component: () => import(/* webpackChunkName: "services-home-route-planning-provider" */ '../views/services/home_services/RoutePlanningProvider.vue'),
                                    },
                                    {
                                        path: '/services/home/:service_id/route-planning/calls',
                                        name: 'home_services_route_planning_calls',
                                        component: () => import(/* webpackChunkName: "services-home-route-planning-calls" */ '../views/services/home_services/RoutePlanningCalls.vue'),
                                    },
                                    {
                                        path: '/services/home/:service_id/route-planning/staff-management',
                                        name: 'home_services_route_planning_staff_management',
                                        component: () => import(/* webpackChunkName: "services-home-route-planning-staff-management" */ '../views/services/home_services/RoutePlanningStaffManagement.vue'),
                                    },
                                    {
                                        path: '/services/home/:service_id/route-planning/overview',
                                        name: 'home_services_route_planning_overview',
                                        component: () => import(/* webpackChunkName: "services-home-route-planning-overview" */ '../views/services/home_services/RoutePlanningOverview.vue'),
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: '/services/supporting',
                name: 'ServicesSupportingServicesRouter',
                component: () => import(/* webpackChunkName: "services-supporting-services-router" */ '../views/services/routers/ServicesSupportingServicesRouter.vue'),
                children: [
                    {
                        path: '/services/supporting/selection',
                        name: 'ServicesSupportingServicesSelection',
                        component: () => import(/* webpackChunkName: "services-supporting-services-selection" */ '../views/services/service_loader/ServiceLoaderSupportingServices.vue')
                    },
                    {
                        path: '/services/supporting/home/:service_id',
                        name: 'ServicesSupportingServicesHome',
                        component: () => import(/* webpackChunkName: "services-home" */ '../views/services/SupportingServicesHome.vue'),
                        children: [
                            {
                                path: '/services/supporting/home/:service_id/overview',
                                name: 'supporting_services_home_services_overview',
                                //redirect: '/services/supporting/home/:service_id/client-list',
                                component: () => import(/* webpackChunkName: "services-home-overview" */ '../views/services/home_services/Overview.vue'),
                            },
                            {
                                path: '/services/supporting/home/:service_id/client-list',
                                name: 'supporting_services_home_services_client_list',
                                component: () => import(/* webpackChunkName: "services-home-client-list" */ '../views/services/home_services/ClientList.vue'),
                            },
                            {
                                path: '/services/supporting/home/:service_id/waiting-list',
                                name: 'supporting_services_home_services_waiting_list',
                                component: () => import(/* webpackChunkName: "services-home-waiting-list" */ '../views/services/home_services/WaitingList.vue'),
                            },
                            {
                                path: '/services/supporting/home/:service_id/referrals',
                                name: 'supporting_services_home_services_referrals',
                                component: () => import(/* webpackChunkName: "services-home-referrals" */ '../views/services/home_services/Referrals.vue'),
                            },
                            {
                                path: '/services/supporting/home/:service_id/movers',
                                name: 'supporting_services_home_services_movers',
                                component: () => import(/* webpackChunkName: "services-home-movers" */ '../views/services/home_services/Movers.vue'),
                            },
                            {
                                path: '/services/supporting/home/:service_id/staff-management',
                                name: 'supporting_services_home_services_staff_management',
                                component: () => import(/* webpackChunkName: "services-home-staff-management" */ '../views/services/home_services/StaffManagement.vue'),
                            },
                            {
                                path: '/services/supporting/home/:service_id/shift-management',
                                name: 'supporting_services_home_services_shift_management',
                                component: () => import(/* webpackChunkName: "services-home-shift-management" */ '../views/services/home_services/ShiftManagement.vue'),
                            },
                            {
                                path: '/services/supporting/home/:service_id/route-planning',
                                name: 'supporting_services_home_services_route_planning',
                                component: () => import(/* webpackChunkName: "services-home-route-planning" */ '../views/services/home_services/RoutePlanning.vue'),
                                children: [
                                    {
                                        path: '/services/supporting/home/:service_id/route-planning/provider',
                                        name: 'supporting_services_home_services_route_planning_provider',
                                        component: () => import(/* webpackChunkName: "services-home-route-planning-provider" */ '../views/services/home_services/RoutePlanningProvider.vue'),
                                    },
                                    {
                                        path: '/services/supporting/home/:service_id/route-planning/calls',
                                        name: 'supporting_services_home_services_route_planning_calls',
                                        component: () => import(/* webpackChunkName: "services-home-route-planning-calls" */ '../views/services/home_services/RoutePlanningCalls.vue'),
                                    },
                                    {
                                        path: '/services/supporting/home/:service_id/route-planning/staff-management',
                                        name: 'supporting_services_home_services_route_planning_staff_management',
                                        component: () => import(/* webpackChunkName: "services-home-route-planning-staff-management" */ '../views/services/home_services/RoutePlanningStaffManagement.vue'),
                                    },
                                    {
                                        path: '/services/supporting/home/:service_id/route-planning/overview',
                                        name: 'supporting_services_home_services_route_planning_overview',
                                        component: () => import(/* webpackChunkName: "services-home-route-planning-overview" */ '../views/services/home_services/RoutePlanningOverview.vue'),
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: '/records/patient-record',
                name: 'records_patient_record',
                component: () => import(/* webpackChunkName: "records-patient-record" */ '../views/records/patientRecord.vue'),
                children: [
                    {
                        path: '/records/patient-record/general/:service_id/:client_id',
                        name: 'records_patient_record_general',
                        component: () => import(/* webpackChunkName: "services-overview" */ '../views/records/patient_record_tabs/general.vue'),
                    },
                    {
                        path: '/records/patient-record/service-information/:service_id/:client_id',
                        name: 'records_patient_record_service_information',
                        component: () => import(/* webpackChunkName: "services-service-information" */ '../views/records/patient_record_tabs/serviceInformation.vue'),
                    },
                    {
                        path: '/records/patient-record/patient-journey/:service_id/:client_id',
                        name: 'records_patient_record_patient_journey',
                        component: () => import(/* webpackChunkName: "services-patient-journey" */ '../views/records/patient_record_tabs/patientJourney.vue'),
                    }
                ]
            }
        ]
    },
    {
        path: '/error-404',
        name: 'page-error-404',
        redirect: '/',
        component: () => import(/* webpackChunkName: "error-404" */ '../views/Error404.vue')
    },
    {
        path: '*',
        redirect: '/'
    }
];

const router = new VueRouter({
    mode: process.env.IS_ELECTRON ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {

    if(to.name != from.name) {
        var custom_date_time_picker = document.querySelectorAll('.date-time-picker-custom');
        custom_date_time_picker.forEach(custom_el => {
            custom_el.parentNode.removeChild(custom_el);
        });
        custom_date_time_picker = null;
    }

    store.state.address_loading = true;
    if(to.path === '/') {
        store.state.current_page_name = 'home';
    }
    if ((from.name === 'home_services_route_planning_calls' || from.name === 'supporting_services_home_services_route_planning_calls') && to.name != 'home_services_route_planning_calls') {
        eventBus.$emit('hide_missing_call_draggable');
        eventBus.$emit('hide_missing_staff_draggable');
        eventBus.$emit('hide_missing_runs_draggable');
    }
    if ((from.name === 'home_services_route_planning_calls' && store.state.isRoutePlanningEditing === 1) || (from.name === 'supporting_services_home_services_route_planning_calls' && store.state.isRoutePlanningEditing === 1)) {
        /*if(store.state.isRoutePlanningEditing === 1) {
            var details = {
                path: from.fullPath,
                care_provider_id: from.query.care_provider_id,
                home_call_structure_id: from.query.home_call_structure_id,
                start_date: from.query.start_date
            };
            store.state.isRoutePlanningEditing === 0;
            Vue.prototype.$socket.client.volatile.emit('unlock_route_planning', details);
        }*/
    }
    if (to.name != 'Login' && to.name != 'ForgotPassword' && to.name != 'DownloadApp' && to.name != 'page-error-404' && to.name != 'AccountActivation' && to.name != 'FirstLogin') {
        userAPI.test_user().then(function (returned_data) {
            if(returned_data.isOk === 1) {
                if(to.name === 'ServicesHomeSelection' || to.name === 'ServicesAcuteSelection' || to.name === 'ServicesCommunityHospitalSelection' || to.name === 'ServicesSupportingServicesSelection') {
                    var service_type = '', service_name = '';
                    if(to.name === 'ServicesHomeSelection'){service_type = 'Home Service'; service_name = 'home_services_overview';}
                    else if(to.name === 'ServicesAcuteSelection'){service_type = 'Acute Hospital'; service_name = 'acute_services_overview';}
                    else if(to.name === 'ServicesCommunityHospitalSelection'){service_type = 'Community Hospital'; service_name = 'community_hospital_services_overview';}
                    else if(to.name === 'ServicesSupportingServicesSelection'){service_type = 'Supporting Services'; service_name = '';}
                    var total_services = 0;
                    var found_service_id = 0;
                    var found_service_type = '';
                    for (var i = 0; i < store.state.service_access.length; i++) {
                        if(store.state.service_access[i].show_under_service_type === service_type) {
                            if(total_services === 0) {
                                found_service_id = store.state.service_access[i].service_id;
                                if(store.state.service_access[i].show_under_service_type === 'Supporting Services') {
                                    if(store.state.service_access[i].service_type === 'Home Service') {
                                        found_service_type = 'supporting_services_home_services_overview';
                                    } else if (store.state.service_access[i].service_type === 'Acute Hospital') {
                                        found_service_type = 'supporting_services_acute_services_overview';
                                    } else if (store.state.service_access[i].service_type === 'Community Hospital') {
                                        found_service_type = 'supporting_services_community_hospital_services_overview';
                                    }
                                }
                            }
                            total_services++;
                        }
                    }
                    if(total_services > 1) {
                        return next();
                    } else {
                        if(total_services === 1) {
                            if(to.name === 'ServicesHomeSelection'){return next({name: service_name, params: {'service_id': found_service_id}});}
                            else if(to.name === 'ServicesAcuteSelection'){return next({name: service_name, params: {'service_id': found_service_id}});}
                            else if(to.name === 'ServicesCommunityHospitalSelection'){return next({name: service_name, params: {'service_id': found_service_id}});}
                            else if(to.name === 'ServicesSupportingServicesSelection'){return next({name: found_service_type, params: {'service_id': found_service_id}});}
                            else {return next('/error-404');}
                        } else {
                            return next();
                        }
                    }
                } else {
                    if(to.name === 'home_services_route_planning') {
                        return next('/services/home/' + to.params.service_id + '/route-planning/provider');
                    } else {
                        if(to.name === 'supporting_services_home_services_route_planning') {
                            return next('/services/supporting/home/' + to.params.service_id + '/route-planning/provider');
                        } else {
                            return next();
                        }
                    }
                }
            } else {
                if(returned_data.err) {
                    eventBus.$emit('network_issue', returned_data);
                    return next();
                } else {
                    if(returned_data.message) {
                        eventBus.$emit("error_display", returned_data.message);
                    }
                    return next({path: '/login', query: {redirect: to.path}});
                }
            }
        });
    } else {
        Vue.prototype.$socket.client.close();
        return next();
    }
});

router.afterEach((to, from) => {
    store.state.address_loading = false;
    Vue.prototype.$socket.client.volatile.emit('change_page', to.fullPath);
});


export default router;
