import axios from 'axios'
import pjson from '../../package.json'

axios.defaults.baseURL = process.env.VUE_APP_cdt_api_address;
axios.defaults.withCredentials = true;

export default {
    test_user() {
        return axios.get('/test/user', { params: { version: pjson.version } }).then(function (response) {
            return response.data;
        }).catch(err => {
            var isOk = {isOk: 0, message: '', err: err};
            return isOk;
        });
    },
    login(username, password) {
        return axios.post('/auth/signin', {
            username: username,
            password: password,
            browser_name: "blank",
            browser_version: "0", 
            version: pjson.version
        }).then(response => {
            return response.data
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: err.response.data, err: err};
                    return return_data;
                } else {
                    return err;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    logout() {
        return axios.get('/logout').then(function (response) {
            return response.data;
        }).catch(err => {
            return err;
        });
    },
    activate_account(activation_key) {
        return axios.post('/activate-account', {
            activation_key: activation_key, 
            version: pjson.version
        }).then(response => {
            return response.data
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'There was a problem activating the account. Please try again', err: err};
                    return return_data;
                } else {
                    return err;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    get_permissions() {
        return axios.get('/get_user_permissions', { params: { version: pjson.version } }).then(function(response) {
            return response.data;
        }).catch(function(err) {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    update_home_page(page) {
        return axios.post('/update_user_home_page', { page: page, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    get_permissions_single_service(service_id) {
        return axios.get('/get_user_permissions_single_service', { params: {service_id: service_id, version: pjson.version }}).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });;
    },
    password_reset(username) {
        return axios.get('/password-reset', {params: { username: username, version: pjson.version }}).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    password_reset_logged_in_request() {
        return axios.get('/password-reset-logged-in-request', { params: { version: pjson.version } }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    password_change_not_logged_in(special_link, password_, auth_code) {
        return axios.post('/password-change-not-logged-in', { special_link: special_link, password_: password_, auth_code: auth_code, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    password_change_logged_in(old_password_, new_password_, conf_password_, auth_code) {
        return axios.post('/password-change-logged-in', { old_password_: old_password_, new_password_: new_password_, conf_password_: conf_password_, auth_code: auth_code, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    password_change_first_log_in(password_) {
        return axios.post('/password-change-first-login', { password_: password_, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    update_user_settings(update_array) {
        return axios.post('/update_user_settings', { settings_update: update_array, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    save_data_preset(update_array) {
        return axios.post('/update_user_preset', { update_array: update_array, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    manage_data_preset(update_array) {
        return axios.post('/manage_data_preset', { update_array: update_array, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    delete_data_preset(id) {
        return axios.post('/delete_data_preset', { id: id, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    get_user_presets(service_id, list_type) {
        return axios.get('/get_user_presets', {params: { service_id: service_id, list_type: list_type, version: pjson.version }}).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },



    getExportPresets(service_id, list_type) {
        return axios.get('/get_user_export_presets', { params: { service_id: service_id, list_type: list_type, version: pjson.version }}).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },

    save_export_preset(update_array) {
        return axios.post('/update_export_preset', { update_array: update_array, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    manage_export_preset(update_array) {
        return axios.post('/manage_export_preset', { update_array: update_array, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    delete_export_preset(id) {
        return axios.post('/delete_export_preset', { id: id, version: pjson.version }).then(function (response) {
            return response.data;
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: 'Unauthorised', err: err};
                    return return_data;
                } else {
                    var return_data = {isOk: 0, message: err, err: err};
                    return return_data;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    },
    
    //,
    /*refresh_demo_data() {
        return axios.get('/refresh_demo_data').then(response => {
            return response.data
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = [{isOk: 0, message: 'Unauthorised', err: err}];
                    return return_data;
                } else {
                    var return_data = [{isOk: 0, message: err, err: err}];
                    return return_data;
                }
            } else {
                var return_data = [{isOk: 0, message: err.message, err: err}];
                return return_data;
            }
        });
    }*/
}