import axios from 'axios'
import pjson from '../../package.json'

axios.defaults.baseURL = process.env.VUE_APP_cdt_api_address;
axios.defaults.withCredentials = true;

export default {
    report_error(update_array) {
        return axios.post('/error_reporting', { update_array: update_array, version: pjson.version }).then(response => {
            return response.data
        }).catch(err => {
            if(err.response != undefined){
                if (err.response.status === 401) {
                    var return_data = {isOk: 0, message: err.response.data, err: err};
                    return return_data;
                } else {
                    return err;
                }
            } else {
                var return_data = {isOk: 0, message: err.message, err: err};
                return return_data;
            }
        });
    }
}