import Common from "./common.js";
import moment from 'moment';
export default {
    getData(columns, data, file_format) {
        var return_data = []
        var column_data = columns;
        var newline = file_format === '.xlsx' ? '\n' : ', ';
        var patient_identifiers = {};

        for(var pt = 0; pt < data.length; pt++) {
            for (var cl = 0; cl < column_data.length; cl++) {
                if (data[pt][column_data[cl].field] != undefined) {
                    if (column_data[cl].field == "patient_identifiers") {
                        var split_identifiers_temp = data[pt][column_data[cl].field].split("~~");
                        for (var pi = 0; pi < split_identifiers_temp.length; pi++) {
                            var split_identifier_header_temp = split_identifiers_temp[pi].split('|');
                            if(patient_identifiers[split_identifier_header_temp[0]] === undefined) {
                                patient_identifiers[split_identifier_header_temp[0]] = '';
                            }
                        }
                    }
                }
            }
        }

        for (var t = 0; t < data.length; t++) {
            var multi_array = {};
            for (var i = 0; i < column_data.length; i++) {
                if (column_data[i].isExport == 1) {
                    if (data[t][column_data[i].field] != undefined) {
                        if(data[t][column_data[i].field] === null || data[t][column_data[i].field] === '') {
                            multi_array[[column_data[i].label]] = ' - ';
                        } else {
                            if (column_data[i].field == "patient_identifiers") {
                                for (var patient_identifier in patient_identifiers) {
                                    multi_array[patient_identifier] = ' - ';

                                    var split_identifiers = data[t][column_data[i].field].split("~~");
                                    for (var p = 0; p < split_identifiers.length; p++) {
                                        var split_identifier_header = split_identifiers[p].split('|');
                                        if(split_identifier_header[0] === patient_identifier) {
                                            multi_array[patient_identifier] = Common.remove_excel_exploit(Common.remove_codebreaks(split_identifier_header[1]));
                                        }
                                    }
                                }
                            } else if (column_data[i].field == "calls") {
                                multi_array[[column_data[i].label]] = this.get_call_detail(data[t][column_data[i].field], data[t].tracked_by);
                            } else if (column_data[i].field == "notification_detail") {
                                multi_array[[column_data[i].label]] = this.get_notification_detail(data[t][column_data[i].field]);
                            } else if (column_data[i].field == "client_flags") {
                                multi_array[[column_data[i].label]] = this.get_patient_client_flags(data[t][column_data[i].field]);
                            }/* else if (column_data[i].field == "alerts") {
                                multi_array[[column_data[i].label]] = this.get_patient_alert_tags(data[t][column_data[i].field]);
                            }*/ else if (column_data[i].field == "level") {
                                multi_array[[column_data[i].label]] = parseInt(data[t][column_data[i].field]);
                            } else {
                                if(column_data[i].isJSON === undefined || column_data[i].isJSON === null || column_data[i].isJSON === 0) {
                                    multi_array[[column_data[i].label]] = Common.remove_excel_exploit(Common.remove_codebreaks(data[t][column_data[i].field]));
                                } else {
                                    if(column_data[i].isNotes === undefined || column_data[i].isNotes === null || column_data[i].isNotes === 0) {
                                        for (var js = 0; js < data[t][column_data[i].field].length ; js++) {
                                            if(multi_array[[column_data[i].label]] === '' || multi_array[[column_data[i].label]] === undefined || multi_array[[column_data[i].label]] === null) {
                                                multi_array[[column_data[i].label]] = Common.remove_excel_exploit(Common.remove_codebreaks(data[t][column_data[i].field][js].description));
                                            } else {
                                                multi_array[[column_data[i].label]] = multi_array[[column_data[i].label]] + newline + Common.remove_excel_exploit(Common.remove_codebreaks(data[t][column_data[i].field][js].description));
                                            }
                                        }
                                    } else {
                                        if(data[t][column_data[i].field] === null) {
                                            multi_array[[column_data[i].label]] = ' - ';
                                        } else {
                                            var notes = JSON.parse(data[t][column_data[i].field]);
                                            for (var js = 0; js < notes.length ; js++) {
                                                if(multi_array[[column_data[i].label]] === '' || multi_array[[column_data[i].label]] === undefined || multi_array[[column_data[i].label]] === null) {
                                                    multi_array[[column_data[i].label]] = Common.remove_excel_exploit(Common.remove_codebreaks(notes[js].forename)) + ' ' + Common.remove_excel_exploit(Common.remove_codebreaks(notes[js].surname)) + ' (' + moment(Common.remove_excel_exploit(Common.remove_codebreaks(notes[js].createdAt))).format("DD/MM/YYYY HH:mm") + '): ' + Common.remove_excel_exploit(Common.remove_codebreaks(notes[js].input_notes));
                                                } else {
                                                    multi_array[[column_data[i].label]] = multi_array[[column_data[i].label]] + newline + newline + Common.remove_excel_exploit(Common.remove_codebreaks(notes[js].forename)) + ' ' + Common.remove_excel_exploit(Common.remove_codebreaks(notes[js].surname)) + ' (' + moment(Common.remove_excel_exploit(Common.remove_codebreaks(notes[js].createdAt))).format("DD/MM/YYYY HH:mm") + '): ' + Common.remove_excel_exploit(Common.remove_codebreaks(notes[js].input_notes));
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        multi_array[[column_data[i].label]] = ' - ';
                    }
                }
            }
            return_data.push(multi_array);
        }
        return return_data;
    },
    get_call_detail(data, tracking_care_provider) {
        var call_longnames_with_info = '';
        var call_list = data.split("~");
        for (var ca = 0; ca < call_list.length; ca++) {
            var call_list_detail = call_list[ca].split("|");
            if(call_list_detail[13] != tracking_care_provider) {
                if (call_longnames_with_info == "") {
                    call_longnames_with_info = call_list_detail[11] + ' (' + call_list_detail[12] + ' ' + call_list_detail[3] + ' mins provided by ' + call_list_detail[13] + ')';
                } else {
                    call_longnames_with_info = call_longnames_with_info + ', ' + call_list_detail[11] + ' (' + call_list_detail[12] + ' ' + call_list_detail[3] + ' mins provided by ' + call_list_detail[13] + ')';
                }
            } else {
                if (call_longnames_with_info == "") {
                    call_longnames_with_info = call_list_detail[11] + ' (' + call_list_detail[12] + ' ' + call_list_detail[3] + ' mins)';
                } else {
                    call_longnames_with_info = call_longnames_with_info + ', ' + call_list_detail[11] + ' (' + call_list_detail[12] + ' ' + call_list_detail[3] + ' mins)';
                }
            }
        }
        return call_longnames_with_info;
    },
    get_notification_detail(data) {
        var notification_detail = '';
        var split_notification = data.split("~~");
        if (split_notification.length > 0){
          for (var i = 0; i < split_notification.length; i++){
            var split_alert_detail = split_notification[i].split("||");
            if (notification_detail == "") {
                notification_detail = split_alert_detail[1] ;
            } else {
                notification_detail = notification_detail + ', ' + split_alert_detail[1];
            }
          }
        }
        return notification_detail;
    },
    getDataOverview(data) {
        var return_data = data;
        return return_data;
    },
    get_patient_alert_tags(data) {
        var alert_detail = '';
        if(data != null){
          var split_alerts = data.split(",");
          for (var i = 0; i < split_alerts.length; i++){
            var split_alert_detail = split_alerts[i].split("|");
            if (alert_detail == "") {
                alert_detail = split_alert_detail[0] ;
            } else {
                alert_detail = alert_detail + ', ' + split_alert_detail[0];
            }
          }
        }
        return alert_detail;
    },
    get_patient_client_flags(data) {
        var client_flag_detail = '';
        if(data != null){
            var split_alerts = data.split("~");
            for (var i = 0; i < split_alerts.length; i++){
            if (client_flag_detail == "") {
                client_flag_detail = split_alerts[i] ;
            } else {
                client_flag_detail = client_flag_detail + ', ' + split_alerts[i];
            }
            }
        }
        return client_flag_detail;
    },
    get_patient_waiting_for_flags(data) {
        var return_data = data;
        return return_data;
    },
    fitToColumn(data_array) {
        var columnWidths = [];    
        for (var property in data_array[0]) {      
            columnWidths.push({wch: Math.max(property ? property.toString().length + 2 : 0,...data_array.map(obj => obj[property] ? obj[property].toString().length: 0))});   
        }    
        return columnWidths;
    },
    async export_list(columns, data, filename, tab_name, file_format) {
        var XLSX = await import('sheetjs-style');
        var exportable_list = this.getData(columns, data, file_format);
        var data_array = XLSX.utils.json_to_sheet(exportable_list);
        const wb = XLSX.utils.book_new();

        if(file_format === '.xlsx') {
            var wscols = this.fitToColumn(exportable_list);

            data_array['!cols'] = wscols;
        
            for (const property in data_array) {
                if(property != '!cols' && property != '!ref') {
                    var hasNumber = /\d/;
                    if(!hasNumber.test(property.replace(/1/, ''))) {
                        data_array[property].s = {
                            font: {bold: '1'}, 
                            border: {
                                top: {style: 'thin', color: { auto: 1}},
                                bottom: {style: 'thin', color: { auto: 1}},
                                left: {style: 'thin', color: { auto: 1}},
                                right: {style: 'thin', color: { auto: 1}}
                            }
                        };
                    } else {
                        data_array[property].s = {
                            alignment: {wrapText: '1'},
                            border: {
                                top: {style: 'dotted', color: { auto: 1}},
                                bottom: {style: 'dotted', color: { auto: 1}},
                                left: {style: 'dotted', color: { auto: 1}},
                                right: {style: 'dotted', color: { auto: 1}}
                            }
                        };
                    }
                }
            }
        }

        XLSX.utils.book_append_sheet(wb, data_array, tab_name);

        if(exportable_list.length > 0) {            
            var first_cell = 'A1';
            var last_cell = null;
            var data_keys = Object.keys(data_array);
            var keys = Object.keys(exportable_list[exportable_list.length - 1]);

            for(var i = 0; i < data_keys.length; i++) {
                if(data_array[data_keys[i]].v) {
                    if(data_array[data_keys[i]].v === keys[keys.length - 1]) {
                        last_cell = (data_keys[i].replace(/[0-9]/g, '')) + (data.length + 1);
                    }
                }
            }

            if(last_cell) {
                data_array['!autofilter'] = { ref: first_cell + ':' + last_cell };
            }
        }

        XLSX.writeFile(wb, filename + file_format);
    }
}