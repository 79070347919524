export default {
    render_table_list(list_type, column, data) {
        if(data === null) {
            return ' - ';
        } else {
            return data;
        }
    },
    render_table_list_other(data) {
        return data;
    },
    get_service_listing_status_from_multiple(data) {
        var return_data = {class: '', text: ''};

        var listing_status = '';
        var listing_status_count = 0;
        var row_count = 0;

        for(var i = 0; i < data.length; i++) {
            if(listing_status === '') {
                listing_status = data[i].listing_status;
                listing_status_count++;
            } else {
                if(listing_status === data[i].listing_status) {
                    listing_status_count++;
                }
            }
            row_count++;
        }

        if(listing_status_count === row_count && listing_status_count != 0) {
            if(listing_status.includes('Start Date Offered - ')) {
                return_data = {
                    text: listing_status,
                    class: "service_listing_green"
                };
            } else if (listing_status === 'Accepted - On the Waiting List') {
                return_data = {
                    text: listing_status,
                    class: "service_listing_green"
                };
            } else if (listing_status === 'Rejected') {
                return_data = {
                    text: listing_status,
                    class: "service_listing_red"
                };
            } else if (listing_status === 'Error') {
                return_data = {
                    text: listing_status,
                    class: "service_listing_red"
                };
            } else if (listing_status === 'Referral Received') {
                return_data = {
                    text: listing_status,
                    class: "service_listing_amber"
                };
            } else if (listing_status === 'Cancelled by Referrer') {
                return_data = {
                    text: listing_status,
                    class: "service_listing_red"
                };
            } else if (listing_status === 'No Longer Listed') {
                return_data = {
                    text: listing_status,
                    class: "service_listing_white"
                };
            } else {
                return_data = {
                    text: listing_status,
                    class: "service_listing_white"
                };
            }
        } else {
            return_data = {
                text: listing_status,
                class: "service_listing_white"
            };
        }

        return return_data;
    },
    get_service_listing_status_class(data){
        var return_data = {class: '', text: ''};

        return_data.text = data;

        if(data != null){
            if(data.includes('Start Date Offered - ')) {
                return_data.class="service_listing_green";
            } else if (data === 'Accepted - On the Waiting List') {
                return_data.class="service_listing_green";
            } else if (data === 'Rejected') {
                return_data.class="service_listing_red";
            } else if (data === 'Error') {
                return_data.class="service_listing_red";
            } else if (data === 'Referral Received') {
                return_data.class="service_listing_amber";
            } else if (data === 'Cancelled by Referrer') {
                return_data.class="service_listing_red";
            } else if (data === 'No Longer Listed') {
                return_data.class="service_listing_white";
            }
        }

        return return_data;
    },
    get_service_listing_status_tag(data) {
        var status_return;
        if(data.includes('Start Date Offered - ')) {
            status_return = {
                text: data,
                type: "is-success"
            };
        } else if (data === 'Accepted - On the Waiting List') {
            status_return = {
                text: data,
                type: "is-success"
            };
        } else if (data === 'Rejected') {
            status_return = {
                text: data,
                type: "is-danger"
            };
        } else if (data === 'Error') {
            status_return = {
                text: data,
                type: "is-danger"
            };
        } else if (data === 'Referral Received') {
            status_return = {
                text: data,
                type: "is-warning"
            };
        } else if (data === 'Cancelled by Referrer') {
            status_return = {
                text: data,
                type: "is-danger"
            };
        } else if (data === 'No Longer Listed') {
            status_return = {
                text: data,
                type: "is-light"
            };
        } else {
            status_return = {
                text: data,
                type: "is-light"
            };
        }
        return status_return;
    },
    get_ticker(data) {
        var date_timestamp = new Date(data).getTime();
        var date_now = new Date().getTime();
        var d = Math.abs(date_timestamp - date_now) / 1000;
        var r = {};
        var s = {
            //week: 604800, // uncomment row to ignore
            day: 86400,
            hour: 3600,
            minute: 60
        };

        Object.keys(s).forEach(function(key){
            r[key] = Math.floor(d / s[key]);
            d -= r[key] * s[key];
        });

        var ticker_to_string = '';

        if(r.day != 0) {
            if(r.day > 0) {
                ticker_to_string = r.day + ' days';
            } else if (r.day === 1) {
                ticker_to_string = r.day + ' day';
            } else {
                ticker_to_string = r.day + ' days';
            }
        }

        if(r.hour != 0) {
            if(r.hour > 0) {
                if(ticker_to_string === '') {
                    ticker_to_string = r.hour + ' hours';
                } else {
                    ticker_to_string = ticker_to_string + ', ' + r.hour + ' hours';
                }
            } else if (r.hour === 1) {
                if(ticker_to_string === '') {
                    ticker_to_string = r.hour + ' hour';
                } else {
                    ticker_to_string = ticker_to_string + ', ' + r.hour + ' hour';
                }
            } else {
                if(ticker_to_string === '') {
                    ticker_to_string = r.hour + ' hours';
                } else {
                    ticker_to_string = ticker_to_string + ', ' + r.hour + ' hours';
                }
            }
        }

        if(r.minute > 0) {
            if(ticker_to_string === '') {
                ticker_to_string = r.minute + ' minutes';
            } else {
                ticker_to_string = ticker_to_string + ', ' + r.minute + ' minutes';
            }
        } else if (r.minute === 1) {
            if(ticker_to_string === '') {
                ticker_to_string = r.minute + ' minute';
            } else {
                ticker_to_string = ticker_to_string + ', ' + r.minute + ' minute';
            }
        } else {
            if(ticker_to_string === '') {
                ticker_to_string = r.minute + ' minutes';
            } else {
                ticker_to_string = ticker_to_string + ', ' + r.minute + ' minutes';
            }
        }
        
        if(date_timestamp > date_now) {
            if(ticker_to_string != '') {
                ticker_to_string = '-' + ticker_to_string;
            }
        }

        return ticker_to_string;
    },
    return_client_list_status(data) {
        var status_return;
        if (data == "On Hold") {
            status_return = {
                text: "On Hold",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'On Hold - With Hold End Date') {
            status_return = {
                text: "On Hold - With Hold End Date",
                type: "is-hold-end-date",
                order: 4
            };
        } else if (data == 'Not Started') {
            status_return = {
                text: "Not Started",
                type: "is-danger",
                order: 3
            };
        } else if (data == 'Starting Today') {
            status_return = {
                text: "Starting Today",
                type: "is-starting",
                order: 6
            };
        } else if (data == 'Started Today') {
            status_return = {
                text: "Started Today",
                type: "is-info",
                order: 8
            };
        } else if (data == 'No Current Care Episode') {
            status_return = {
                text: "No Current Care Episode",
                type: "is-danger",
                order: 2
            };
        } else if (data == 'Outstanding Actions') {
            status_return = {
                text: "Outstanding Actions",
                type: "is-warning",
                order: 5
            };
        } else if (data == 'Active - No Issues') {
            status_return = {
                text: "Active - No Issues",
                type: "is-success",
                order: 1
            };
        } else if (data == 'Planned Discharge Today') {
            status_return = {
                text: "Planned Discharge Today",
                type: "is-purple",
                order: 7
            };
        } else if (data == 'Starting Today - No longer planned for discharge') {
            status_return = {
                text: "Starting Today - No longer planned for discharge",
                type: "is-starting-no-more",
                order: 8
            };
        } else if (data == 'Starting Today - Client has passed away') {
            status_return = {
                text: "Starting Today - Client has passed away",
                type: "is-starting-rip",
                order: 8
            };
        } else if (data == 'Starting Today - Pending Discharge') {
            status_return = {
                text: "Starting Today - Pending Discharge",
                type: "is-starting",
                order: 8
            };
        } else if (data == 'Starting Today - Discharged') {
            status_return = {
                text: "Starting Today - Discharged Confirmed",
                type: "is-starting",
                order: 8
            };
        } else if (data == 'Starting Today - Not listed against this service') {
            status_return = {
                text: "Starting Today - Not listed against this service",
                type: "is-starting-not-listed-against-this-service",
                order: 8
            };
        } else if (data == 'Started Today - No longer planned for discharge') {
            status_return = {
                text: "Started Today - No longer planned for discharge",
                type: "is-started-no-more",
                order: 8
            };
        } else if (data == 'Started Today - Client has passed away') {
            status_return = {
                text: "Started Today - Client has passed away",
                type: "is-started-rip",
                order: 8
            };
        } else if (data == 'Started Today - Pending Discharge') {
            status_return = {
                text: "Started Today - Pending Discharge",
                type: "is-started-pending",
                order: 8
            };
        } else if (data == 'Started Today - Discharged') {
            status_return = {
                text: "Started Today - Discharged Confirmed",
                type: "is-info",
                order: 8
            };
        } else if (data == 'Started Today - Not listed against this service') {
            status_return = {
                text: "Started Today - Not listed against this service",
                type: "is-started-not-listed-against-this-service",
                order: 8
            };
        } else if (data == 'Pending discharge to another service') {
            status_return = {
                text: "Pending discharge to another service",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'Client has passed away before being discharged') {
            status_return = {
                text: "Client has passed away before being discharged",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'No longer planned for discharge') {
            status_return = {
                text: "No longer planned for discharge",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'Discharged from sending service') {
            status_return = {
                text: "Discharged from sending service",
                type: "is-success",
                order: 4
            };
        } else if (data == 'Pending Discharge from sending service') {
            status_return = {
                text: "Pending Discharge from sending service",
                type: "is-warning",
                order: 4
            };
        } else {
            status_return = {
                text: data,
                type: "is-success",
                order: 1
            };
        }
        return status_return;
    },
    return_bed_patient_list_status(data) {
        var status_return;
        if (data == "Not Medically Fit") {
            status_return = {
                text: "Not Medically Fit",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'Not Medically Fit - With Hold End Date') {
            status_return = {
                text: "Not Medically Fit - With Hold End Date",
                type: "is-hold-end-date",
                order: 4
            };
        } else if (data == 'Not Started') {
            status_return = {
                text: "Not Started",
                type: "is-danger",
                order: 3
            };
        } else if (data == 'On Patient List') {
            status_return = {
                text: "On Patient List",
                type: "is-warning",
                order: 6
            };
        } else if (data == 'Starting Today') {
            status_return = {
                text: "Starting Today",
                type: "is-starting",
                order: 6
            };
        } else if (data == 'Started Today') {
            status_return = {
                text: "Started Today",
                type: "is-info",
                order: 8
            };
        } else if (data == 'No Current Care Episode') {
            status_return = {
                text: "No Current Care Episode",
                type: "is-danger",
                order: 2
            };
        } else if (data == 'Outstanding Actions') {
            status_return = {
                text: "Outstanding Actions",
                type: "is-warning",
                order: 5
            };
        } else if (data == 'Active - No Issues') {
            status_return = {
                text: "Active - No Issues",
                type: "is-success",
                order: 1
            };
        } else if (data == 'Planned Discharge Today') {
            status_return = {
                text: "Planned Discharge Today",
                type: "is-purple",
                order: 7
            };
        } else if (data == 'Starting Today - No longer planned for discharge') {
            status_return = {
                text: "Starting Today - No longer planned for discharge",
                type: "is-starting-no-more",
                order: 8
            };
        } else if (data == 'Starting Today - Client has passed away') {
            status_return = {
                text: "Starting Today - Client has passed away",
                type: "is-starting-rip",
                order: 8
            };
        } else if (data == 'Starting Today - Pending Discharge') {
            status_return = {
                text: "Starting Today - Pending Discharge",
                type: "is-starting",
                order: 8
            };
        } else if (data == 'Starting Today - Discharged') {
            status_return = {
                text: "Starting Today - Discharged Confirmed",
                type: "is-starting",
                order: 8
            };
        } else if (data == 'Starting Today - Not listed against this service') {
            status_return = {
                text: "Starting Today - Not listed against this service",
                type: "is-starting-not-listed-against-this-service",
                order: 8
            };
        } else if (data == 'Started Today - No longer planned for discharge') {
            status_return = {
                text: "Started Today - No longer planned for discharge",
                type: "is-started-no-more",
                order: 8
            };
        } else if (data == 'Started Today - Client has passed away') {
            status_return = {
                text: "Started Today - Client has passed away",
                type: "is-started-rip",
                order: 8
            };
        } else if (data == 'Started Today - Pending Discharge') {
            status_return = {
                text: "Started Today - Pending Discharge",
                type: "is-started-pending",
                order: 8
            };
        } else if (data == 'Started Today - Discharged') {
            status_return = {
                text: "Started Today - Discharged Confirmed",
                type: "is-info",
                order: 8
            };
        } else if (data == 'Started Today - Not listed against this service') {
            status_return = {
                text: "Started Today - Not listed against this service",
                type: "is-started-not-listed-against-this-service",
                order: 8
            };
        } else if (data == 'Not listed against this service') {
            status_return = {
                text: "Not listed against this service",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'Pending discharge to another service') {
            status_return = {
                text: "Pending discharge to another service",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'Client has passed away before being discharged') {
            status_return = {
                text: "Client has passed away before being discharged",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'No longer planned for discharge') {
            status_return = {
                text: "No longer planned for discharge",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'Discharged from sending service') {
            status_return = {
                text: "Discharged from sending service",
                type: "is-success",
                order: 4
            };
        } else if (data == 'Pending Discharge from sending service') {
            status_return = {
                text: "Pending Discharge from sending service",
                type: "is-warning",
                order: 4
            };
        } else if (data == 'Discharged from sending service to a different service') {
            status_return = {
                text: "Discharged from sending service to a different service",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'Discharged from sending service to this service') {
            status_return = {
                text: "Discharged from sending service to this service",
                type: "is-warning",
                order: 4
            };
        } else {
            status_return = {
                text: data,
                type: "is-success",
                order: 1
            };
        }
        return status_return;
    },
    return_rag(data) {
        var rag_return;
        if (data == "Red") {
            rag_return = {
                text: "Red",
                type: "is-danger"
            };
        } else if (data == 'Amber') {
            rag_return = {
                text: "Amber",
                type: "is-warning"
            };
        } else if (data == 'Green') {
            rag_return = {
                text: "Green",
                type: "is-success"
            };
        } else {
            rag_return = {
                text: "N/A",
                type: "is-light"
            };
        }
        return rag_return;
    },
    return_call_icons(call_info) {
        if(call_info != null) {
            var call_detail = [];

            var call_list = call_info.split("~");

            for (var i = 0; i < call_list.length; i++) {
                var call_list_detail = call_list[i].split("|");
                if (call_list_detail[15] == 1) {
                    call_detail.push({
                        title: call_list_detail[11],
                        short_name: call_list_detail[16],
                        call_structure: call_list_detail[12],
                        class: "table_call call_span_1"
                    });
                } else if (call_list_detail[15] == 2) {
                    call_detail.push({
                        title: call_list_detail[11],
                        short_name: call_list_detail[16],
                        call_structure: call_list_detail[12],
                        class: "table_call call_span_2"
                    });
                } else if (call_list_detail[15] >= 3) {
                    call_detail.push({
                        title: call_list_detail[11],
                        short_name: call_list_detail[16],
                        call_structure: call_list_detail[12],
                        class: "table_call call_span_plus"
                    });
                }
            }
        } else {
            call_detail = null;
        }
        return call_detail;
    },
    return_external_calls(data) {
        var rag_return;
        if (data == "None") {
            rag_return = {
                text: "None",
                type: "is-success"
            };
        } else if (data == 'All Calls') {
            rag_return = {
                text: "All Calls",
                type: "is-danger"
            };
        } else if (data == 'Part') {
            rag_return = {
                text: "Part",
                type: "is-warning"
            };
        } else {
            rag_return = {
                text: " - ",
                type: "is-light"
            };
        }
        return rag_return;
    },
    return_waiting_list_status(data) {
        var status_return;
        if (data == "Negative Days Waiting") {
            status_return = {
                text: "Negative Days Waiting",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'Listed Today') {
            status_return = {
                text: "Listed Today",
                type: "is-info",
                order: 3
            };
        } else if (data == 'Outstanding Actions') {
            status_return = {
                text: "Outstanding Actions",
                type: "is-warning",
                order: 5
            };
        } else if (data == 'Over 10 Days Waiting') {
            status_return = {
                text: "Over 10 Days Waiting",
                type: "is-danger",
                order: 6
            };
        } else if (data == 'Active - No Issues') {
            status_return = {
                text: "Active - No Issues",
                type: "is-success",
                order: 2
            };
        } else if (data == 'Not listed against this service') {
            status_return = {
                text: "Not listed against this service",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'Discharged from sending service to a different service') {
            status_return = {
                text: "Discharged from sending service to a different service",
                type: "is-danger",
                order: 4
            };
        } else if (data == 'Discharged from sending service to this service') {
            status_return = {
                text: "Discharged from sending service to this service",
                type: "is-warning",
                order: 4
            };
        } else if (data == 'Pending discharge to another service') {
            status_return = {
                text: "Pending discharge to another service",
                type: "is-danger",
                order: 4
            };
        }
        return status_return;
    },
    return_movers_list_status(data) {
        var status_return;
        if (data == "Potential") {
            status_return = {
                text: "Potential",
                type: "is-white",
                order: 4
            };
        } else if (data == 'Confirmed') {
            status_return = {
                text: "Confirmed",
                type: "is-danger",
                order: 3
            };
        } else if (data == 'Planned') {
            status_return = {
                text: "Planned",
                type: "is-purple",
                order: 6
            };
        } else if (data == 'Service Ended') {
            status_return = {
                text: "Service Ended",
                type: "is-success",
                order: 2
            };
        } else if (data == 'Deceased') {
            status_return = {
                text: "Deceased",
                type: "is-dark",
                order: 5
            };
        } else if (data == 'Not Ended'){
            status_return = {
                text: "Not Ended",
                type: "is-danger",
                order: 1
            };
        } else {
            status_return = {
                text: data,
                type: "is-success",
                order: 1
            };
        }
        return status_return;
    },
    return_bed_movers_list_status(data) {
        var status_return;
        if (data == "Potential") {
            status_return = {
                text: "Potential",
                type: "is-white",
                order: 4
            };
        } else if (data == 'Confirmed') {
            status_return = {
                text: "Confirmed",
                type: "is-danger",
                order: 3
            };
        } else if (data == 'Planned') {
            status_return = {
                text: "Planned",
                type: "is-purple",
                order: 6
            };
        } else if (data == 'Discharged') {
            status_return = {
                text: "Discharged",
                type: "is-success",
                order: 2
            };
        } else if (data == 'Deceased') {
            status_return = {
                text: "Deceased",
                type: "is-dark",
                order: 5
            };
        } else if (data == 'Not Discharged'){
            status_return = {
                text: "Not Discharged",
                type: "is-danger",
                order: 1
            };
        } else {
            status_return = {
                text: data,
                type: "is-success",
                order: 1
            };
        }
        return status_return;
    },
    get_total_patient_identifiers(data) {
        var return_data = [];
        if(data != null) {
            var split_identifiers = data.split("~~");
            for (var i = 0; i < split_identifiers.length; i++){
                var split_identifier_header = split_identifiers[i].split('|');
                var found = false;
                for (var t = 0; t < return_data.length; t++){
                    if(return_data[t] == split_identifier_header[0]){
                        found = true;
                    }
                }
                if(!found){
                    return_data.push({
                        field: split_identifier_header[0],
                        value: split_identifier_header[1]
                    });
                }
            }
        }
        return return_data;
    },
    get_patient_identifer_columns(data) {
        var return_data = [];
        if(data != null) {
            var split_identifiers = data.split("~~");
            for (var i = 0; i < split_identifiers.length; i++){
                var split_identifier_header = split_identifiers[i].split('|');
                var found = false;
                for (var t = 0; t < return_data.length; t++){
                    if(return_data[t] == split_identifier_header[0]){
                        found = true;
                    }
                }
                if(!found){
                    return_data.push({
                        field: split_identifier_header[0]
                    });
                }
            }
        }
        return return_data;
    },
    get_patient_identifer_values(data, field) {
        var return_data = '';
        if(data != null) {
            var split_identifiers = data.split("~~");
            for (var i = 0; i < split_identifiers.length; i++){
                var split_identifier_header = split_identifiers[i].split('|');
                if(split_identifier_header[0] == field) {
                    return_data = split_identifier_header[1];
                }
            }
        }
        return return_data;
    },
    get_patient_address_fields(data) {
        var return_data = [];
        var split_address = data.split("<br>");
        for (var i = 0; i < split_address.length; i++){
            return_data.push({
                field: split_address[i]
            });
        }
        return return_data;
    },
    get_patient_address_fields_minimal(data) {
        var return_data = [];
        var split_address = data.split("<br>");
        return_data.push({
            field: split_address[0] + ', '
        });
        return_data.push({
            field: split_address[2] + ', '
        });
        return_data.push({
            field: split_address[split_address.length - 1]
        });
        return return_data;
    },
    get_patient_alert_tags(data) {
        var return_data = [];
        if(data != null){
            var split_alerts = data.split(",");
            for (var i = 0; i < split_alerts.length; i++){
                var split_alert_detail = split_alerts[i].split("|");
                return_data.push({
                    field: split_alert_detail[0]
                });
            }
        }
        return return_data;
    },
    get_patient_staff_conflict_tags(data) {
        var return_data = [];
        if(data != null){
            var split_alerts = data.split(",");
            for (var i = 0; i < split_alerts.length; i++){
                var split_alert_detail = split_alerts[i].split("|");
                return_data.push({
                    field: '(' + split_alert_detail[0] + ') ' + split_alert_detail[1] + ' ' + split_alert_detail[2]
                });
            }
        }
        return return_data;
    },
    get_patient_client_flags(data) {
        var return_data = [];
        if(data != null){
            var split_alerts = data.split("~");
                for (var i = 0; i < split_alerts.length; i++){
                return_data.push({
                    field: split_alerts[i]
                });
            }
        }
        return return_data;
    },
    get_patient_notifications(data) {
        var return_data = [];
        if(data != null && data != ''){
            var split_alerts = data.split("~~");
            if (split_alerts.length > 0){
                for (var i = 0; i < split_alerts.length; i++){
                    var split_alert_detail = split_alerts[i].split("||");
                    var isMDI = 0;
                    var icon = '';
                    var class_ = '';
                    if(split_alert_detail[0] == 'Reviews'){
                        isMDI = 0;
                        icon = 'book-open';
                        class_ = 'detail-icon-yellow';
                    } else if (split_alert_detail[0] == 'Barthel') {
                        isMDI = 0;
                        icon = 'flag';
                        class_ = 'detail-icon-light-blue';
                    } else if (split_alert_detail[0] == 'On Hold') {
                        isMDI = 0;
                        icon = 'hand-paper';
                        class_ = 'detail-icon-red';
                    } else if (split_alert_detail[0] == 'Actions') {
                        isMDI = 1;
                        icon = 'mdi-alarm-light-outline';
                        class_ = 'detail-icon-mdi-red';
                    }
                    return_data.push({
                        field: split_alert_detail[0],
                        text: split_alert_detail[1],
                        isMDI: isMDI,
                        icon: icon,
                        class: class_
                    });
                }
            } else {
                return_data.push({
                    field: 'No Notifications',
                    text: 'There are no notifications for this client.',
                    icon: 'check',
                    class: 'detail-icon-light-green'
                });
            }
        } else {
          return_data.push({
                field: 'No Notifications',
                text: 'There are no notifications for this client.',
                isMDI: 0,
                icon: 'check',
                class: 'detail-icon-light-green'
          });
        }
        return return_data;
    },
    return_account_status(data) {
        var status_return;
        if (data === "Active") {
            status_return = {
                text: "Active",
                type: "is-success",
                order: 4
            };
        } else if (data === 'Locked') {
            status_return = {
                text: "Locked",
                type: "is-danger",
                order: 3
            };
        } else if (data === "Not Yet Activated") {
            status_return = {
                text: "Not Yet Activated",
                type: "is-warning",
                order: 2
            };
        }
        return status_return;
    },
    return_staff_status(data){
        var status_return;
        if (data === "Active") {
            status_return = {
                text: "Active",
                type: "is-success",
                order: 4
            };
        } else if (data === 'Ended') {
            status_return = {
                text: "Ended",
                type: "is-danger",
                order: 3
            };
        }
        return status_return;
    },
    get_level_progress_percentage(data){
        var experience_start = data.experience_start;
        var experience_end = data.experience_end;
        var experience = data.experience;

        var level_total_xp;
        var level_progress;
        var level_progress_percentage;

        if(parseInt(data.level) === 1) {
            if(experience === experience_end){
                level_progress_percentage = 100;
            } else if (experience === experience_start) {
                level_progress_percentage = 0;
            } else {
                level_progress_percentage = Math.round((parseInt(experience) / parseInt(experience_end)) * 100).toFixed(0);
            }
        } else {
            if(experience === experience_end){
                level_progress_percentage = 100;
            } else if (experience === experience_start) {
                level_progress_percentage = 0;
            } else {
                level_total_xp = parseInt(experience_end) - parseInt(experience_start);
                level_progress = parseInt(experience) - parseInt(experience_start);
                level_progress_percentage = Math.round((level_progress / level_total_xp) * 100).toFixed(0);
            }
        }

        return level_progress_percentage;
    },
    user_level_progress(data){
        var experience_start = data.experience_start;
        var experience_end = data.experience_end;
        var experience = data.user_experience;

        var level_total_xp;
        var level_progress;
        var level_progress_percentage;

        if(parseInt(data.level) === 1) {
            if(experience === experience_end){
                level_progress_percentage = 100;
            } else if (experience === experience_start) {
                level_progress_percentage = 0;
            } else {
                level_progress_percentage = Math.round((parseInt(experience) / parseInt(experience_end)) * 100).toFixed(0);
            }
        } else {
            if(experience === experience_end){
                level_progress_percentage = 100;
            } else if (experience === experience_start) {
                level_progress_percentage = 0;
            } else {
                level_total_xp = parseInt(experience_end) - parseInt(experience_start);
                level_progress = parseInt(experience) - parseInt(experience_start);
                level_progress_percentage = Math.round((level_progress / level_total_xp) * 100).toFixed(0);
            }
        }

        return level_progress_percentage;
    }
}