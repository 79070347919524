import themes from './themes';
export default {
    get_themes() {
        return themes;
    },
    change_theme(theme_id, this_, colour_blind_mode) {
        var theme_array;

        try {
            theme_array = themes[theme_id];
        } catch {
            theme_array = themes[1];
        }

        let root = document.documentElement;
        root.style.setProperty('--primary', theme_array["primary" + colour_blind_mode]);
        root.style.setProperty('--primary-rgb', theme_array["primary-rgb"]);
        root.style.setProperty('--primary-text', theme_array["primary-text"]);
        root.style.setProperty('--primary-text-hover', theme_array["primary-text-hover"]);
        root.style.setProperty('--link-hover', theme_array["link-hover"]);
        root.style.setProperty('--selected-text', theme_array["selected-text"]);
        root.style.setProperty('--primary-button', theme_array["primary-button"]);
        root.style.setProperty('--primary-button-hover', theme_array["primary-button-hover"]);
        root.style.setProperty('--primary-button-background', theme_array["primary-button-background"]);
        root.style.setProperty('--primary-button-background-hover', theme_array["primary-button-background-hover"]);
        root.style.setProperty('--primary-menu-text', theme_array["primary-menu-text"]);
        root.style.setProperty('--background-color', theme_array["background-color"]);
        root.style.setProperty('--control-border-colour', theme_array["control-border-colour"]);
        root.style.setProperty('--nav-color', theme_array["nav-color"]);
        root.style.setProperty('--sidebar-color', theme_array["sidebar-color"]);
        root.style.setProperty('--sidebar-active-link-background-color', theme_array["sidebar-active-link-background-color"]);
        root.style.setProperty('--sidebar-active-link-color', theme_array["sidebar-active-link-color"]);
        root.style.setProperty('--sidebar-shadow-color', theme_array["sidebar-shadow-color"]);

        root.style.setProperty('--tab-group-active', theme_array["tab-group-active"]);
        root.style.setProperty('--tab-group-not-active', theme_array["tab-group-not-active"]);

        root.style.setProperty('--popup-background', theme_array["popup-background"]);
        root.style.setProperty('--dialog-background', theme_array["dialog-background"]);

        root.style.setProperty('--datepicker-background-color', theme_array["datepicker-background-color"]);
        root.style.setProperty('--datepicker-shadow', theme_array["datepicker-shadow" + colour_blind_mode]);
        root.style.setProperty('--background-color-form-head', theme_array["background-color-form-head"]);
        root.style.setProperty('--filter-gradient-one', theme_array["filter-gradient-one"]);
        root.style.setProperty('--filter-gradient-two', theme_array["filter-gradient-two"]);
        root.style.setProperty('--filter-gradient-three', theme_array["filter-gradient-three"]);
        root.style.setProperty('--filter-gradient-four', theme_array["filter-gradient-four"]);
        root.style.setProperty('--shimmerfourtyfive', theme_array["shimmerfourtyfive" + colour_blind_mode]);
        root.style.setProperty('--shimmerfifty', theme_array["shimmerfifty" + colour_blind_mode]);
        root.style.setProperty('--shimmerfiftyfive', theme_array["shimmerfiftyfive" + colour_blind_mode]);
        root.style.setProperty('--tablerowhighlightfirst', theme_array["tablerowhighlightfirst" + colour_blind_mode]);
        root.style.setProperty('--tablerowhighlightsecond', theme_array["tablerowhighlightsecond" + colour_blind_mode]);
        root.style.setProperty('--tablerowhighlightthird', theme_array["tablerowhighlightthird" + colour_blind_mode]);
        root.style.setProperty('--pulse-color-green', theme_array["pulse-color-green"]);
        root.style.setProperty('--pulse-color-amber', theme_array["pulse-color-amber"]);
        root.style.setProperty('--pulse-color-red', theme_array["pulse-color-red"]);
        root.style.setProperty('--exp-animation-hue', parseInt(theme_array["exp-animation-hue"]));

        this_.$store.state.theme_id = theme_id;

        if(theme_array['name'] === 'Halloween') {
            this_.$store.state.notification_sound = '/sound/Halloween/notification.mp3';
            this_.$store.state.error_sound = '/sound/Halloween/error.mp3';
        } else if (theme_array['name'] === 'Christmas') {
            this_.$store.state.notification_sound = '/sound/Christmas/notification.mp3';
            this_.$store.state.error_sound = '/sound/Christmas/error.mp3';
        } else if (theme_array['name'] === 'Forest') {
            this_.$store.state.notification_sound = '/sound/Forest/notification.mp3';
            this_.$store.state.error_sound = '/sound/Forest/error.mp3';
        } else {
            this_.$store.state.notification_sound = '/sound/Default/notification.mp3';
            this_.$store.state.error_sound = '/sound/Default/error.mp3';
        }
    },
    change_background(theme_id) {
        var theme_array = themes[theme_id];
        var theme_name;

        try {
            theme_name = theme_array["name"];
        } catch {
            theme_array = themes[1];
            theme_name = theme_array["name"];
        }

        var main_page = document.getElementsByClassName("v-main"), main_page_gradient = document.getElementsByClassName("main-page-background-gradient"), sidebar = document.getElementsByClassName("v-navigation-drawer__content");
        
        try {
            main_page[0].style.backgroundImage = "url('/images/backgrounds/" + theme_array["background-image"] + "')";
        } catch(err_main_bg){console.log(err_main_bg);}

        for (var mpg = 0; mpg < main_page_gradient.length; mpg++) {
            try {
                main_page_gradient[mpg].style.background = "linear-gradient(" + theme_array["background-gradient-one"] + ", " + theme_array["background-gradient-two"] + ")";
            } catch(err_main_pg_gr){}
        }
        
        try {
            if(theme_array["main-sidebar-image"] != 'none') {
                sidebar[0].style.background = "url('/images/sidebar/" +  theme_array["main-sidebar-image"] + "')";
            } else {
                sidebar[0].style.background = "none";
            }
        } catch(err_sb) {}
    },
    get_theme_particles(this_, theme_id) {
        var theme_array = themes[theme_id];
        var theme_particles;

        if(this_.$store.state.show_animations === true) {
            try {
                theme_particles = theme_array.particles;
            } catch {
                theme_array = themes[1];
                theme_particles = theme_array.particles;
            }
            return theme_array.particles;
        } else {
            return null
        }  
    },
    get_vvanta_particles(this_, theme_id) {
        var theme_array = themes[theme_id];
        var theme_particles = {show_vanta: false, vanta_animation: null};

        if(this_.$store.state.show_animations === true) {
            try {
                theme_particles.show_vanta = theme_array.show_vanta;
                theme_particles.vanta_animation = theme_array.vanta_animation;
            } catch {
                theme_particles.show_vanta = false;
                theme_particles.vanta_animation = null;
            }
            return theme_particles;
        } else {
            theme_particles.show_vanta = false;
            theme_particles.vanta_animation = null;
            return theme_particles;
        }  
    },
    get_theme_video(this_, theme_id) {
        var theme_array = themes[theme_id];
        var theme_video = {};

        if(this_.$store.state.show_animations === true) {
            try {
                theme_video.video = theme_array.video;
                theme_video.playback_rate = theme_array.playback_rate;
            } catch {
                theme_array = themes[1];
                theme_video.video = theme_array.video;
                theme_video.playback_rate = theme_array.playback_rate;
            }
            return theme_video;
        } else {
            theme_video.video = null;
            theme_video.playback_rate = null;
            return theme_video;
        }
    },
    get_theme_sidebar(theme_id, this_) {
        var theme_array = themes[theme_id];
        var theme_name;

        try {
            theme_name = theme_array["name"];
        } catch {
            theme_array = themes[1];
            theme_name = theme_array["name"];
        }

        var sidebar_head_item = null;
        var sidebar_content_item = null;

        if(this_.$store.state.sidebar_head_item != null) {
            document.head.removeChild(this_.$store.state.sidebar_head_item);
            this_.$store.state.sidebar_head_item = null;
        }

        if(this_.$store.state.sidebar_content_item != null) {
            document.head.removeChild(this_.$store.state.sidebar_content_item);
            this_.$store.state.sidebar_content_item = null;
        }
        
        if(theme_array["name"] === "Halloween"){
            var sidebar_overlay_halloween = document.getElementsByClassName("sidebar-overlay"); 
            if(sidebar_overlay_halloween.length > 0){
                sidebar_overlay_halloween[0].style.backgroundImage = "url('/images/events/halloween_web.png')";
                sidebar_overlay_halloween[0].style.backgroundSize = "contain";
                sidebar_overlay_halloween[0].style.backgroundRepeat = "no-repeat";
                sidebar_overlay_halloween[0].style.filter = "invert(50%)";
                sidebar_overlay_halloween[0].style.backgroundPositionY = "0px";
                sidebar_overlay_halloween[0].style.opacity = "1";
            }

            sidebar_head_item = document.createElement('style');
            sidebar_head_item.innerHTML = `
            .modal.is-active {
                background-image: url('/images/events/halloween_menus.png');
                background-repeat: no-repeat;
                /* background-color: rgb(0 0 255 / 0%) !important; */
                background-size: 100% 100%;
                /* opacity: 0.5; */
            }
            .search_sidebar_wrapper_background {
                background-image: url('/images/events/halloween_menus.png');
                background-repeat: no-repeat;
                /* background-color: rgb(0 0 255 / 0%) !important; */
                background-size: 100% 100%;
            }
            `;
            document.head.appendChild(sidebar_head_item);
        } else if(theme_array["name"] === "Christmas"){
            var sidebar_overlay_christmas = document.getElementsByClassName("sidebar-overlay"); 
            if(sidebar_overlay_christmas.length > 0){
                sidebar_overlay_christmas[0].style.backgroundImage = "url('/images/events/frost.png')";
                sidebar_overlay_christmas[0].style.backgroundSize = "auto";
                sidebar_overlay_christmas[0].style.backgroundRepeat = "no-repeat";
                sidebar_overlay_christmas[0].style.filter = "invert(0%)";
                sidebar_overlay_christmas[0].style.backgroundPositionY = "0px";
                sidebar_overlay_christmas[0].style.opacity = "1";
            }

            sidebar_head_item = document.createElement('style');
            sidebar_head_item.innerHTML = `
            .modal.is-active {
                background-image: url('/images/events/christmas_menus.png');
                background-repeat: no-repeat;
                /* background-color: rgb(0 0 255 / 0%) !important; */
                background-size: 100% 100%;
                /* opacity: 0.2; */
            }
            .search_sidebar_wrapper_background {
                background-image: url('/images/events/christmas_menus.png');
                background-repeat: no-repeat;
                /* background-color: rgb(0 0 255 / 0%) !important; */
                background-size: 100% 100%;
            }
            `;
            document.head.appendChild(sidebar_head_item);
        } else if(theme_array["name"] === "Forest"){
            var sidebar_overlay_forest = document.getElementsByClassName("sidebar-overlay"); 
            if(sidebar_overlay_forest.length > 0){
                sidebar_overlay_forest[0].style.backgroundImage = "url('/images/sidebar overlay/vine.png')";
                sidebar_overlay_forest[0].style.backgroundSize = "auto";
                sidebar_overlay_forest[0].style.backgroundPositionY = "-98px";
                sidebar_overlay_forest[0].style.opacity = "0.5";
                sidebar_overlay_forest[0].style.backgroundRepeat = "no-repeat";
                sidebar_overlay_forest[0].style.filter = "invert(0%)";
            }

            sidebar_head_item = document.createElement('style');
            sidebar_head_item.innerHTML = `
            .modal.is-active {
                background-image: url('/images/sidebar overlay/vine.png');
                background-repeat: no-repeat;
                /* background-color: rgb(0 0 255 / 0%) !important; */
                background-size: 100% 100%;
                background-position-y: -29px;
                /* opacity: 0.5; */
            }
            .search_sidebar_wrapper_background {
                background-image: url('/images/sidebar overlay/vine.png');
                background-repeat: no-repeat;
                /* background-color: rgb(0 0 255 / 0%) !important; */
                background-size: 100% 100%;
                background-position-y: -29px;
            }
            `;
            document.head.appendChild(sidebar_head_item);
        } else if(theme_array["name"] === "Clouds" || theme_array["name"] === "Clouds 2"){
            sidebar_head_item = document.createElement('style');
            sidebar_head_item.innerHTML = `
            .modal.is-active {
                background-image: url('/images/events/cloud_menu.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .search_sidebar_wrapper_background {
                background-image: url('/images/events/cloud_menu.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            `;
            document.head.appendChild(sidebar_head_item);
        } else {
            var sidebar_overlay_ = document.getElementsByClassName("sidebar-overlay"); 
            if(sidebar_overlay_.length > 0){
                sidebar_overlay_[0].style.backgroundImage = "none";
            }
        }
        try {
            if(theme_array["main-sidebar-image"] === "none"){
                sidebar_content_item = document.createElement('style');
                sidebar_content_item.innerHTML = `
                    .vs-content-sidebar {
                        background-image: none !important;
                    }
                `;
                document.head.appendChild(sidebar_content_item);
            } else {
                sidebar_content_item = document.createElement('style');
                sidebar_content_item.innerHTML = `
                    .vs-content-sidebar {
                        background-image: url('/images/sidebar/${theme_array["main-sidebar-image"]}');
                    }
                `;
                document.head.appendChild(sidebar_content_item);
            }
        } catch(err) {console.log(err);}

        if(sidebar_head_item != null) {
            this_.$store.state.sidebar_head_item = sidebar_head_item;
        }

        if(sidebar_content_item != null) {
            this_.$store.state.sidebar_content_item = sidebar_content_item;
        }
    }
}