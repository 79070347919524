import { eventBus } from "../main";
import store from '../store/index.js';

export default {
    remove_codebreaks(string) {
        try {
            var replace_string = string.replace(/\\/g, "");
            return replace_string
        } catch (err) {
            return string
        }
    },
    add_codebreaks(string) {
        try {
            var replace_string = string.replace(/\'/g, "\\'").replace(/\"/g, '\\"').replace(/</g, '').replace(/>/g, '');
            return replace_string
        } catch (err) {
            return string
        }
    },
    remove_excel_exploit(string) {
        try {
            var replace_string = '';
            if(string.toString().charAt(0) === '=' || string.toString().charAt(0) === '@') {
                replace_string = '/' + string;
            } else {
                replace_string = string;
            }
            return replace_string;
        } catch (err) {
            return string;
        }
    },
    test_is_app() {
        var isElectron = false;
        try{
            isElectron = process.env.IS_ELECTRON;
        } catch(err){
            isElectron = false;
        }
        if(isElectron === undefined) {
            isElectron = false;
        }
        return isElectron;
    },
    getColorVariable(color_variable) {
        return getComputedStyle(document.documentElement).getPropertyValue('--' + color_variable);
    },
    get_nav_permissions(this_, service_type, permission_group, permission_description) {
        var isAuth = false;
        if(this_.$store.state.service_access != null) {
            for(var i = 0; i < this_.$store.state.service_access.length; i++) {
                if(parseInt(this_.$store.state.service_access[i].service_id) === parseInt(this_.$route.params.service_id)) {
                    if(this_.$store.state.service_access[i].service_type === service_type) {
                        var permission_json = JSON.parse(this_.$store.state.service_access[i].permissions);
                        permission_json = JSON.parse(permission_json.permissions);
                        for (var t = 0; t < permission_json.length; t++) {
                            if(isAuth === false){
                                if(permission_json[t].group_description === permission_group && permission_json[t].access_description === permission_description) {
                                    isAuth = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return isAuth;
    },
    formatDate(date){
        var date_ = date.toISOString().slice(0, 10);
        return date_;
    },
    formatTime(time){
        var time_ = time.toLocaleTimeString();
        return time_;
    },
    formatDateTime(datetime) {
        var date = datetime.toISOString().slice(0, 10);
        var time = datetime.toLocaleTimeString();
        return date + ' ' + time;
    },
    getNewDateTimeFromTime(this_, time) {
        var datetime = new Date(this_.moment(this_.moment().format("YYYY-MM-DD") + ' ' + time));
        return datetime;
    },
    check_features(list, feature_id) {
        var return_value = false;
        if(list != null) {
            if(list.length > 0) {
                for(var i = 0; i < list.length; i++) {
                    if(parseInt(list[i].id) === parseInt(feature_id)) {
                        return_value = true;
                    }
                }
            }
        }
        return return_value;
    },
    check_permissions(service_id, permission_group, permission_description) {
        var isAuth = false;
        for (var i = 0; i < store.state.service_access.length; i++) {
            if(parseInt(store.state.service_access[i].service_id) === parseInt(service_id)) {
                var permission_json = JSON.parse(store.state.service_access[i].permissions);
                permission_json = JSON.parse(permission_json.permissions);
                for (var t = 0; t < permission_json.length; t++) {
                    if(isAuth === false){
                        if(permission_json[t].group_description === permission_group && permission_json[t].access_description === permission_description) {
                            isAuth = true;
                        }
                    }
                }
            }
        }
        return isAuth;
    },
    check_rota_permissions(service_access, permission_group, permission_description) {
        var isAuth = false;
        if(service_access != null) {
            for (var i = 0; i < service_access.length; i++) {
                var permission_json = JSON.parse(service_access[i].permissions);
                permission_json = JSON.parse(permission_json.permissions);
                if(permission_json != null) {
                    for (var t = 0; t < permission_json.length; t++) {
                        if(isAuth === false){
                            if(permission_json[t].group_description === permission_group && permission_json[t].access_description === permission_description) {
                                isAuth = true;
                            }
                        }
                    }
                }
            }
        }
        return isAuth;
    },
    get_service_count(this_, to) {
        var service_type = '';
        if(to === 'ServicesHomeSelection'){service_type = 'Home Service';}
        else if(to === 'ServicesAcuteSelection'){service_type = 'Acute Hospital';}
        else if(to === 'ServicesCommunityHospitalSelection'){service_type = 'Community Hospital';}

        var total_services = 0;

        for (var i = 0; i < this_.store.state.service_access.length; i++) {
            if(this_.store.state.service_access[i].service_type === service_type) {
                total_services++;
            }
        }

        return total_services;
    },
    bad_return(this_, returned_data) {
        if(returned_data[0].message === 'Unauthorised') {
            this_.$router.replace({path: '/login', query: {redirect: this_.$router.currentRoute.fullPath}});
        } else {
            //this_.$root.vtoast.show({timer: 8000, message: returned_data[0].message, color: 'red', icon: 'mdi-alert-circle'});
            //eventBus.$emit("play_error");

            eventBus.$emit("error_display", returned_data[0].message);
        }
    },
    bad_return_no_object(this_, returned_data) {
        if(returned_data.message === 'Unauthorised') {
            this_.$router.replace({path: '/login', query: {redirect: this_.$router.currentRoute.fullPath}});
        } else {
            //this_.$root.vtoast.show({timer: 8000, message: returned_data.message, color: 'red', icon: 'mdi-alert-circle'});
            //eventBus.$emit("play_error");

            eventBus.$emit("error_display", returned_data.message);
        }
    },
    bad_return_undefined(this_, returned_data) {
        if(returned_data.message === 'Unauthorised' || returned_data.message === 'Expired Session') {
            this_.$router.replace({path: '/login', query: {redirect: this_.$router.currentRoute.fullPath}});
        } else {
            //this_.$root.vtoast.show({timer: 8000, message: returned_data.message, color: 'red', icon: 'mdi-alert-circle'});
            //eventBus.$emit("play_error");
            eventBus.$emit("error_display", returned_data.message);
        }
    },
    get_item_in_array(list, id_name, id, name) {
        var returned_value = null;

        for (var i = 0; i < list.length; i++) {
            if(list[i][id_name] === id) {
                returned_value = list[i][name];
                break;
            }
        }

        return returned_value;
    },
    textarea_autoheight(el) {
        var e = document.getElementById(el);
        e.style.height = "auto";
        e.style.height = e.scrollHeight + "px";
    },
    hasScrollbars_check(scroll_id) {
        var element = document.getElementById(scroll_id);
        if(element) {
            var hasScrollbars_result = element.scrollHeight != element.offsetHeight;
            return hasScrollbars_result;
        } else {
            return false;
        }
    },
    hasLeftScrollButton_check(scroll_id) {
        var element = document.getElementById(scroll_id);
        if(element) {
            if(element.scrollLeft <= 0){
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    },
    hasRightScrollButton_check(scroll_id) {
        var element = document.getElementById(scroll_id);
        if(element) {
            if(Math.ceil(element.scrollLeft) >= (element.scrollWidth - element.clientWidth)){
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    },
    scrollTo(element, scrollPixels, duration) {
        const scrollPos = element.scrollLeft;
        if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
            const startTime =
            "now" in window.performance
                ? performance.now()
                : new Date().getTime();
            
            var scroll = function (timestamp) {
                const timeElapsed = timestamp - startTime;
                const progress = Math.min(timeElapsed / duration, 1);
                element.scrollLeft = scrollPos + scrollPixels * progress;
                if (timeElapsed < duration) {
                    window.requestAnimationFrame(scroll);
                } else {
                    return;
                }
            }
            window.requestAnimationFrame(scroll);
        }
    },
    scroll_left(id) {
        var content = document.querySelector("#" + id);
        this.scrollTo(content, -300, 400);
    },
    scroll_right(id) {
        var content = document.querySelector("#" + id);
        this.scrollTo(content, 300, 400);
    },
    find_start_call_id(call_list, date_start, this_) {
        var call_id = null;
        for (var sc = 0; sc < call_list.length; sc++) {
            var call_start_time = this_.moment.utc(this_.moment(date_start).format("YYYY-MM-DD") + ' ' + call_list[sc].start_time);
            var call_end_time = this_.moment.utc(this_.moment(date_start).format("YYYY-MM-DD") + ' ' + call_list[sc].end_time);

            if(this_.moment.utc(call_end_time).isBefore(call_start_time)) {
                call_end_time = this_.moment.utc(this_.moment(date_start).add(1, 'days').format("YYYY-MM-DD") + ' ' + call_list[sc].end_time);
            }

            var service_type_start = this_.moment.utc(date_start);

            if(this_.moment.utc(service_type_start).isSame(call_start_time)) {
                call_id = call_list[sc].id;
            }
            
            if(this_.moment.utc(service_type_start).isBetween(call_start_time, call_end_time)) {
                call_id = call_list[sc].id;
            }
        }
        return call_id;
    },
    find_end_call_id(call_list, date_end, this_) {
        var call_id = null;
        for (var sc = 0; sc < call_list.length; sc++) {
            if(date_end != null && date_end != '') {
                var call_start_time_end = this_.moment.utc(this_.moment(date_end).format("YYYY-MM-DD") + ' ' + call_list[sc].start_time);
                var call_end_time_end = this_.moment.utc(this_.moment(date_end).format("YYYY-MM-DD") + ' ' + call_list[sc].end_time);
                
                var service_type_end;
                
                service_type_end = this_.moment.utc(date_end);
                
                if(this_.moment.utc(service_type_end).isSame(call_end_time_end)) {
                    call_id = call_list[sc].id;
                }
                
                if(this_.moment.utc(service_type_end).isBetween(call_start_time_end, call_end_time_end)) {
                    call_id = call_list[sc].id;
                }
            }
        }
        return call_id;
    },
    check_client_identifiers(data){
        var reject_messages = [];
        var return_data = {}
        for (var i = 0; i < data.length; i++) {
            if(data[i].isRequired === 1 && (data[i].value === null || data[i].value === '' || data[i].value === undefined)) {
                reject_messages.push({
                    message: data[i].description + ' - Missing values'
                });
            } else {
                if (data[i].characters_allowed === 'Numbers') {
                    var isNumber = isNaN(data[i].value);
                    if(isNumber) {
                        reject_messages.push({
                            message: data[i].description + ' - Numbers only but text was found'
                        });
                    }
                }
                if (data[i].characters_required != 0) {
                    if(data[i].value.replace(/ /g, '').length != data[i].characters_required) {
                        reject_messages.push({
                            message: data[i].description + ' - ' + data[i].characters_required + ' charcters required. ' + data[i].value.length + ' inputted'
                        });
                    }
                }
            }
        }
        if(reject_messages.length > 0) {
            return_data = {
                isOk: 0,
                message: reject_messages
            }
        } else {
            return_data = {
                isOk: 1,
                message: ''
            }
        }
        return return_data;
    },
    get_fixed_2(data) {
        return parseFloat(data).toFixed(2);
    }
}