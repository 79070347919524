import axios from 'axios'

export default {
    test(params) {
        return axios.post('http://18.130.251.35:3000',
            params,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        ).then(function (response) {
            return response;
        }).catch(err => {
            var isOk = {isOk: 0, message: '', err: err};
            return isOk;
        });
    }
}