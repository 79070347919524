<template>
    <div class="password_strength_container">
        <div class="password_strength_bar">
            <div class="password_strength_fill" :data-score="$props.password_input != null && $props.password_input != '' ? password_strength : ''"></div>
        </div>
        <template v-if="$props.password_input != null && $props.password_input != '' && (password_strength >= 0 && password_strength < 3)">
            <div class="weak_password">Too weak</div>
        </template>
        <template v-else-if="$props.password_input != null && $props.password_input != '' && password_strength === 3">
            <div class="moderate_password">Moderate password</div>
        </template>
        <template v-else-if="$props.password_input != null && $props.password_input != '' && password_strength === 4">
            <div class="strong_password">Strong password!</div>
        </template>
    </div>
</template>

<script>
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';
export default {
    data() {
        return {
            options: {
                translations: zxcvbnEnPackage.translations,
                graphs: zxcvbnCommonPackage.adjacencyGraphs,
                dictionary: {
                    ...zxcvbnCommonPackage.dictionary,
                    ...zxcvbnEnPackage.dictionary,
                }
            },

            password_strength: 0
        }
    },
    props: ['password_input'],
    mounted() {
        zxcvbnOptions.setOptions(this.options);
    },
    watch: {
        password_input: function(val) {
            this.password_strength = zxcvbn(val).score;
            this.$emit('password_strength_update', this.password_strength);
        }
    }
}
</script>