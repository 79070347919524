<template>
    <v-app>
        <transition name="fade_loader">
            <fs-loader v-if="show === false"/>
        </transition>
        <error-notifications></error-notifications>
        <router-view></router-view>
    </v-app>
</template>

<script>
import { getCurrentInstance, ref, watch } from "vue";
import { useIdle } from '@vueuse/core';
import fs_loader from "./components/loader/full_screen_loader.vue";
import error_notifications from "./components/error_notifications/error_notifications.vue";
export default {
    setup() {
        const internalInstance = getCurrentInstance();
        const global_props = internalInstance.proxy;
        const { idle } = useIdle(5 * 60 * 1000); // 5 min
        const show = ref(true);

        watch(idle, (idleValue) => {
            if (idleValue) {
                show.value = false;
                global_props.$store.state.isIdle = 1;
                global_props.$socket.client.volatile.emit('user_idle');
            } else {
                show.value = true;
                global_props.$store.state.isIdle = 0;
                global_props.$socket.client.volatile.emit('user_active');
            }
        });

        return {
            show
        }
    },
    components: {
        'fs-loader': fs_loader,
        'error-notifications': error_notifications
    },
    created() {
        var this_ = this;
        this_.$vuetify.theme.dark = true;
        window.addEventListener('resize', () => {
            this_.$store.commit('setWindowWidth');
        });
    }
}
</script>
<style lang="scss">
    @import "~bulma/sass/utilities/_all";
    @import "~bulma";
    @import "~buefy/src/scss/buefy";
    @import "./main";
    @import '~@riophae/vue-treeselect/dist/vue-treeselect.css';
    @import '../public/styles/vuetify.min.css';
</style>